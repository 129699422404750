import React, { useEffect, useState } from "react";
import * as locCon from "../LocalConstants"
import { copyToClipboard, showNotification } from "../utils/generalFunctions";
import * as locGenFun from "../utils/generalFunctions";
import { RefATOField, RefATOInsideSetField, RefStoredValue, StateATOField, StateATOInsideSetField, StateStoredBooleanValue, StateStoredValue } from "../hooks/StoreHooks";
import { RefLanguage } from "../hooks/LanguageHooks";
import { Input, InputNumber } from "antd";
import CustomButton from "./CustomButton";
import CustomText from "./CustomText";
import { ModalHook } from "../hooks/ModalHooks";
import { CheckboxDefaultValue } from "./CustomCheckBox";
import { isNullOrUndefined, isNullOrUndefinedOrEmpty } from "../community-hats-js-library/utils/generalFunctions";



export function SimpleCopyField({ title, value, enableCopy = true, maxLength = 35, onCopyCleanUp = (val) => true }) {

  const i18n = RefLanguage()

  return (
    <div className='verticalSection'>
      {title !== null && title !== undefined ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
      <div style={styles.section}>
        {enableCopy === true ? <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => { locGenFun.copyToClipboard(value, `${title} copied to clipboard`); onCopyCleanUp(value) }}>{i18n.t("copy")}</CustomButton> : <div></div>}
        <CustomText type={locCon.ELEMENT_TEXT} >{value === null || value === undefined ? "----" : value.length >= maxLength ? value.substring(0, maxLength) + "..." : value}</CustomText>
        {enableCopy === true ? <div style={{ width: "15%" }}></div> : <div></div>}
      </div>
    </div>
  )
}




export function SimpleCopyFieldFromId({ valueID, processFunction = (val) => val, ...props }) {

  const value = RefStoredValue(valueID)

  return (
    <SimpleCopyField {...props} value={processFunction(value)} />
  )
}



export function SimpleCopyFieldFromATO({ atoId, fieldId, processFunction = (val) => val, ...props }) {

  const value = RefATOField(atoId, fieldId)

  return (
    <SimpleCopyField {...props} value={processFunction(value)} />
  )
}



export function SimpleCopyFieldFromATOInsideSet({ positionId, atoId, fieldId, processFunction = (val) => val, ...props }) {

  const value = RefATOInsideSetField(positionId, atoId, fieldId)

  return (
    <SimpleCopyField {...props} value={processFunction(value)} />
  )
}









export function SimpleStoreCopyFieldFromATO({ atoId, fieldId, ...props }) {

  const [value, setValue] = StateATOField(atoId, fieldId)
  const [editingValue, setEditingValue] = StateStoredBooleanValue(fieldId + locCon.EDITING_VALUE)

  return (
    <SimpleStoreCopyField value={value} setValue={setValue} editingValue={editingValue} setEditingValue={setEditingValue} {...props} />);
}

export function SimpleStoreCopyFieldFromATOInsideSet({ positionId, atoId, fieldId, ...props }) {

  const [value, setValue] = StateATOInsideSetField(positionId, atoId, fieldId)
  const [editingValue, setEditingValue] = StateStoredBooleanValue(fieldId + "_" + positionId + locCon.EDITING_VALUE)

  return (
    <SimpleStoreCopyField value={value} setValue={setValue} editingValue={editingValue} setEditingValue={setEditingValue} {...props} />
  );
}

export function SimpleStoreCopyFieldFromId({ valueID, ...props }) {

  const [value, setValue] = StateStoredValue(valueID)
  const [editingValue, setEditingValue] = StateStoredBooleanValue(valueID + locCon.EDITING_VALUE)

  return (
    <SimpleStoreCopyField value={value} setValue={setValue} editingValue={editingValue} setEditingValue={setEditingValue} {...props} />
  );
}

const genericCheckFunction = (val) => !isNullOrUndefinedOrEmpty(val)
const genericWarningFunction = (val) => true
const genericSaveFunction = (__, ___) => true


export function SimpleStoreCopyField({ value, setValue, editingValue, setEditingValue, title, placeHolder, enableCopy = true, infoText = null, checkFunction = genericCheckFunction, checkWarningFunction = genericWarningFunction, maxLength = 22, notValidText = null, warningText = null, isInputNumeric = false, includeCheckbox = false, defaultValue, checkboxText, precision = 0, saveCleanFunction = genericSaveFunction }) {


  const i18n = RefLanguage()

  // Modal
  const [modal, openModal] = ModalHook()

  // Value
  const [localValue, setLocalValue] = useState(() => value)


  const editSaveValue = () => {



    if (editingValue) {
      // Checks the value
      if (checkFunction(localValue)) {

        if (checkWarningFunction(localValue)) {
          setValue(localValue)
          setEditingValue(false)
          saveCleanFunction(false, localValue)
        }
        else {
          const text = warningText === null ? i18n.t("unlikelyValueLong") : warningText

          openModal(i18n.t("unlikelyValue"), `${text} Value Entered: ${localValue}`, [
            {
              text: i18n.t("cancel"),
              onClick: () => false,
              type: locCon.MODULE_CANCEL_BUTTON,
            },
            {
              text: i18n.t("valueIsCorrect"),
              onClick: () => { setValue(localValue); setEditingValue(false); saveCleanFunction(false, localValue) },
              type: locCon.MODULE_REGULAR_BUTTON
            }
          ]);

        }

      }
      else {
        let text = i18n.t("enteredValueNotValid")
        if (!isNullOrUndefined(notValidText))
          text = notValidText

        locGenFun.showError(text)
      }


    }
    else {
      setEditingValue(true)
      saveCleanFunction(true, localValue)
      setLocalValue(value)
    }

  }

  const pasteFromClipboard = async () => {

    let copiedValue = await locGenFun.getClipboardValue()
    setLocalValue(copiedValue)

    // Checks the value
    if (checkFunction(copiedValue)) {
      setValue(copiedValue)
      setEditingValue(false)
    }
    else
      showNotification(i18n.t("enteredValueNotValid"))
  }


  useEffect(() => {

    setEditingValue(editingValue || !checkFunction(value))
    setLocalValue(value)

  }, [value, checkFunction, setEditingValue, editingValue])




  return (

    <div className="verticalSection">
      {modal}
      {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
      {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}
      <div style={styles.section}>
        {
          !enableCopy
            ? <div></div>
            : editingValue
              ? <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => pasteFromClipboard()}>{i18n.t("paste")}</CustomButton>
              : <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={() => copyToClipboard(value, `${title} copied to clipboard`)}>{i18n.t("copy")}</CustomButton>
        }
        {
          !editingValue
            ? <CustomText type={locCon.ELEMENT_TEXT}>{value === null || value === undefined ? "----" : value.length >= maxLength ? value.substring(0, maxLength) + "..." : value}</CustomText>
            : isInputNumeric ? <InputNumber inputMode="numeric" style={styles.input} onChange={(val) => setLocalValue(precision > 0 ? parseFloat(val) : parseInt(val))} value={localValue} placeholder={placeHolder} precision={precision} /> : <Input style={styles.input} onChange={(e) => setLocalValue(e.target.value)} value={localValue} placeholder={placeHolder} />
        }
        <CustomButton type={locCon.SAVE_EDIT_BUTTON} onClick={editSaveValue} style={{ marginRight: "4%" }}>{editingValue ? i18n.t("save") : i18n.t("edit")}</CustomButton>
      </div>
      {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={setValue} defaultValue={defaultValue} text={checkboxText} setEditingValue={setEditingValue} /> : <div></div>}
    </div>
  );
}



const styles = {
  section: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: 'stretch',
    marginBottom: "2vh",
    marginTop: "1vh",
    width: "100%"

  },
  input: {
    backgroundColor: "var(--primary-color-5)",
    color: "var(--primary-color-3)",
    fontSize: 16,
    width: "100%"

  }
}









