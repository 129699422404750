// Local Constants

import * as libCon from './community-hats-js-library/Constants'
import { nowInIndia } from './community-hats-js-library/utils/dateFunctions'

// Authentication
export const PASSWORD = "sewa@1972"
export const MAX_IDLE_MINUTES = 60

export const DEBUG = false

// TIme Zone
export const FITBIT_TIME_ZONE = "Asia/Kolkata"

// Pages
export const PAGE_MANAGER_START = '/'
export const PAGE_SET_UP_BUNDLE = '/set_up_bundle'
export const PAGE_DEPLOY_BUNDLE = "/deploy_bundle"
export const PAGE_COLLECT_DATA = "/collect_data"
export const PAGE_SYNC_BUNDLE = "/sync_bundle"
export const PAGE_BUNDLE_STATUS = "/bundle_status"
export const PAGE_BUNDLE_RETRIEVE = "/bundle_retrieve"
export const PAGE_MANAGE_BUNDLE = "/bundle_manage"
export const PAGE_PERCEPTUAL_SURVEY = "/perceptual_survey"
export const PAGE_PARTICIPANT_INTAKE_FORM = "/participant_intake_form"
export const PAGE_HOUSE_INTAKE_FORM = "/house_intake_form"



export const PAGE_MANAGE_BUNDLE_REPLACE_WEARABLE = PAGE_MANAGE_BUNDLE + "/replace_wearable"
export const PAGE_MANAGE_BUNDLE_REMOVE_SENSOR = PAGE_MANAGE_BUNDLE + "/remove_sensor"
export const PAGE_MANAGE_BUNDLE_ADD_SENSOR = PAGE_MANAGE_BUNDLE + "/add_sensor"
export const PAGE_MANAGE_BUNDLE_CHANGE_HOUSE = PAGE_MANAGE_BUNDLE + "/change_house"


export const PAGE_TOOLS_MAIN = "/tools"
export const PAGE_TOOL_LOCATION_EXTRACTOR = PAGE_TOOLS_MAIN + "/location_extractor"
export const PAGE_TOOL_FITBIT_API_UPDATER = PAGE_TOOLS_MAIN + "/fitbit_api_updater"



// Languages
export const BASE_LANGUAGES = "BASE_LANGUAGES"
export const STORAGE_CURRENT_LOCAL = "STORAGE_CURRENT_LOCAL"
export const UPDATING_LANGUAGE = "UPDATING_LANGUAGE"
export const LANGUAGE_LOADING_TIME = 1000


// Buttons
export const PRIMARY_BUTTON = "primary"
export const PRIMARY_BUTTON_DISSABLED_BUTTON = "primary_disabled"
export const DANGER_BUTTON = "danger"
export const SAVE_EDIT_BUTTON = "save_edit_button"
export const SAVE_EDIT_BUTTON_DISABLED = "save_edit_button_disabled"
export const MODULE_REGULAR_BUTTON = "module_regular_button"
export const MODULE_CANCEL_BUTTON = "module_cancel_button"
export const LANGUAGE_BUTTON = "language_button"
export const SELECTED_LANGUAGE_BUTTON = "selected_language_button"
export const LIKERT_BUTTON = "likert_button"
export const SELECTED_LIKERT_BUTTON = "selected_likert_button"





// Spinner
export const BUTTON_SPINNER = "button_spinner"
export const SMALL_SPINNER = "small_spinner"



// Text
export const SECTION_TITLE_TEXT = "section_title"
export const MODULE_TITLE_TEXT = "module_title"
export const INSTRUCTIONS_TEXT = "instructions_text"
export const INFO_TEXT = "info_text"
export const ELEMENT_TITLE_TEXT = "element_title_text"
export const ELEMENT_TEXT = "element_text"
export const SUCCESS_TEXT = "success_text"
export const DEFAULT_INFO_TEXT = "default_info_text"
export const DANGER_TEXT = "danger_text"
export const LONG_TEXT = "long_text"
export const OPTION_TEXT = "option_text"
export const DISABLED_OPTION_TEXT = "disabled_option_text"


// Checkbox
export const MAIN_PROGRESS_CHECKBOX = "main_checkbox"
export const SECONDARY_PROGRESS_CHECKBOX = "secondary_checkbox"
export const DEFAULT_OPTION_CHECKBOX = "default_option_checkbox"
export const MULTIPLE_CHOICE_ANSWER_CHECKBOX = "multiple_choice_answer_checkbox"




// Suffix constants
export const LAST_SEARCHED_VALUE = "__LAST_SEARCHED__VALUE__"
export const EDITING_VALUE = "__EDITING_VALUE__"
export const OTHER_VALUE = "__OTHER_VALUE__"
export const INCLUDE_OTHER_VALUE = "__INCLUE_OTHER_VALUE__"
export const PREVIEW_VALUE = "__PREVIEW__"





// General Store Values
export const RAG_LAST_NAME = "RAG_LAST_NAME"
export const RAG_FIRST_NAME = "RAG_FIRST_NAME"
export const RAG_YEAR_OF_BIRTH = "RAG_YEAR_OF_BIRTH"
export const RAG_HAS_COPIED = "RAG_HAS_COPIED"
export const RAG_HAS_COPIED_EMAIL = "RAG_HAS_COPIED_EMAIL"
export const RAG_HAS_COPIED_PASSWORD = "RAG_HAS_COPIED_PASSWORD"
export const BATTERY_CHANGE_DATE = "BATTERY_CHANGE_DATE"
export const BATTERY_CHANGE_TIME = "BATTERY_CHANGE_TIME"




// Airtable Object Status
export const ATO_EMPTY = "ATO_EMPTY"
export const ATO_ONLY_LOCAL = "ATO_ONLY_LOCAL"
export const ATO_UP_TO_DATE = "ATO_UP_TO_DATE"
export const ATO_NEEDS_SYNCH = "ATO_NEEDS_SYNCH"



// Checkboxes
export const GA_SET_UP_GOOGLE_ACCOUNT = "GA_SET_UP_GOOGLE_ACCOUNT"
export const GA_SET_UP_DROPBOX_ACCOUNT = "GA_SET_UP_DROPBOX_ACCOUNT"
export const GA_DOWNLOAD_DROPBOX_APP = "GA_DOWNLOAD_DROPBOX_APP"
export const GA_CREATE_DROPBOX_ACCOUNT = "GA_CREATE_DROPBOX_ACCOUNT"
export const GA_ACTIVATE_ACCOUNT_EMAIL = "GA_ACTIVATE_ACCOUNT_EMAIL"
export const GA_ADD_EMAIL_TO_DATABASE = "GA_ADD_EMAIL_TO_DATABASE"
export const TA_SET_UP_TRACKER_APP = "TA_SET_UP_TRACKER_APP"
export const TA_DOWNLOAD_TACKER_APP = "TA_DOWNLOAD_TACKER_APP"
export const TA_CONFIGURE_BATTERY = "TA_CONFIGURE_BATTERY"
export const TA_TURN_ON_LOCATION = "TA_TURN_ON_LOCATION"
export const TA_GET_PHONE_SERIAL = "TA_GET_PHONE_SERIAL"
export const TA_LOCATION_PERMISSIONS = "TA_LOCATION_PERMISSIONS"
export const TA_ACTIVATE_TRACKING = "TA_ACTIVATE_TRACKING"
export const TA_CLOSE_APPLICATION = "TA_CLOSE_APPLICATION"
export const FTB_DOWNLOAD_APPLICATION = "FTB_DOWNLOAD_APPLICATION"
export const FTB_CREATE_NEW_ACCOUNT = "FTB_CREATE_NEW_ACCOUNT"
export const FTB_SET_TIME_ZONE = "FTB_SET_TIME_ZONE"
export const FTB_SET_UP_DEVICE = "FTB_SET_UP_DEVICE"
export const FTB_SET_API = "FTB_SET_API"
export const FTB_DEV_LOGIN = "FTB_DEV_LOGIN"
export const FTB_GO_TO_ADD_APP = "FTB_GO_TO_ADD_APP"
export const FTB_FILL_VALUES = "FTB_FILL_VALUES"
export const FTB_COPY_CLIENT_ID = "FTB_COPY_CLIENT_ID"
export const FTB_GO_TO_AUTHORIZATION_PAGE = "FTB_GO_TO_AUTHORIZATION_PAGE"
export const FTB_COPY_TOKEN_AND_REQUEST = "FTB_COPY_TOKEN_AND_REQUEST"
export const FTB_TEST_TOKEN = "FTB_TEST_TOKEN"
export const GTO_SET_UP_GOOGLE_TAKE_OUT = "GTO_SET_UP_GOOGLE_TAKE_OUT"
export const GTO_SCHEDULE_GOOGLE_TAKE_OUT = "GTO_SCHEDULE_GOOGLE_TAKE_OUT"
export const GTO_GENERATE_DB_SHARE_LINK = "GTO_GENERATE_DB_SHARE_LINK"
export const APT_ADD_PARTICIPANT_TO_DATABASE = "APT_ADD_PARTICIPANT_TO_DATABASE"
export const APPD_PHONE_PLACEMENT = "APPD_PHONE_PLACEMENT"
export const APPD_LINK_PHONE_SERIAL = "APPD_LINK_PHONE_SERIAL"
export const APPD_ADD_PHONE_PLACEMENT = "APPD_ADD_PHONE_PLACEMENT"
export const AWP_WEARABLE_PLACEMENT = "AWP_WEARABLE_PLACEMENT"
export const AWP_ADD_WEARABLE_PLACEMENT = "AWP_ADD_WEARABLE_PLACEMENT"
export const AWP_LINK_WEARABLE_SERIAL = "AWP_LINK_WEARABLE_SERIAL"
export const AWP_GET_WEARABLE_SERIAL = "AWP_GET_WEARABLE_SERIAL"
export const ELC_EXTRACT_LOCATIONS_COORDINATES = "ELC_EXTRACT_LOCATIONS_COORDINATES"
export const ALD_ADD_LOCATION_TO_DATABASE = "ALD_ADD_LOCATION_TO_DATABASE"
export const APAR_PARTICIPANT_PLACEMENT = "APAR_PARTICIPANT_PLACEMENT"
export const IS_INSERT_SIM = "IS_INSERT_SIM"
export const ED_EXPLAIN_DUTIES = "ED_EAT_OBJECT_WEARABLEXPLAIN_DUTIES"
export const ED_EXPLAIN_FITBIT = "ED_EXPLAIN_FITBIT"
export const GD_GIVE_DEVICES = "GD_GIVE_DEVICES"
export const GD_DELETE_APP = "GD_DELETE_APP"
export const GD_SHOW_PHONE_PIN = "GD_SHOW_PHONE_PIN"
export const UHW_UPDATE_HEIGHT_AND_WEIGHT = "UHW_UPDATE_HEIGHT_AND_WEIGHT"
export const LTA_RESTART_TRACKING_APP = "LTA_RESTART_TRACKING_APP"
export const LTA_TURN_ON_LOCATION = "LTA_TURN_ON_LOCATION"
export const GAF_GROUP_APPS_IN_FOLDER = "GAF_GROUP_APPS_IN_FOLDER"
export const TB_TAG_BUNDLE = "TB_TAG_BUNDLE"
export const PIF_AMINISTER_INTAKE_FORM = "PIF_AMINISTER_INTAKE_FORM"
export const SUG_SET_UP_SENSOR = "SUG_SET_UP_SENSOR"
export const SUG_DOWNLOAD_APP = "SUG_DOWNLOAD_APP"
export const SUG_ADD_DEVICE_TO_APP = "SUG_ADD_DEVICE_TO_APP"
export const SUG_CREATE_ACCOUNT = "SUG_CREATE_ACCOUNT"
export const SUG_ADD_INBOX_SENSOR = "SUG_ADD_INBOX_SENSOR"

export const SUK_SET_UP_SENSOR = "SUK_SET_UP_SENSOR"
export const SUK_DOWNLOAD_APP = "SUK_DOWNLOAD_APP"
export const SUK_ADD_DEVICE_TO_APP = "SUK_ADD_DEVICE_TO_APP"
export const SUK_SET_LOG_INTERVAL = "SUK_SET_LOG_INTERVAL"
export const SUK_DISABLE_AUTOCONNECT = "SUK_DISABLE_AUTOCONNECT"

export const AS_SENSOR_BASE = "AS_SENSOR_BASE"

export const getASCheckBoxId = (positionID) => AS_SENSOR_BASE + "__" + positionID




export const BD_ADD_BUNDLE_TO_DATABASE = "BD_ADD_BUNDLE_TO_DATABASE"


export const CC_COLLECT_DATA = "CC_COLLECT_DATA"


export const RB_COLLECT_DATA = "RB_COLLECT_DATA"
export const RB_CHECK_BUNDLE = "RB_CHECK_BUNDLE"

// Replace Wearable
export const RW_COLLECT_DATA = "RW_COLLECT_DATA"
export const RW_END_AND_REMOVE_WEARABLE_PLACEMENT = "RW_END_AND_REMOVE_WEARABLE_PLACEMENT"
export const RW_REMOVE_CURRENT_FITBIT_DEVICE = "RW_REMOVE_CURRENT_FITBIT_DEVICE"
export const RW_SET_UP_NEW_DEVICE = "RW_SET_UP_NEW_DEVICE"
export const RW_GET_WEARABLE_SERIAL = "RW_GET_WEARABLE_SERIAL"
export const RW_LINK_WEARABLE_SERIAL = "RW_LINK_WEARABLE_SERIAL"
export const RW_WEARABLE_PLACEMENT = "RW_WEARABLE_PLACEMENT"
export const RW_UPDATE_BUNDLE_TO_DATABASE = "RW_UPDATE_BUNDLE_TO_DATABASE"

// Remove Sensor
export const RS_COLLECT_DATA = "RS_COLLECT_DATA"
export const RS_END_AND_REMOVE_SENSOR_PLACEMENT = "RS_END_AND_REMOVE_SENSOR_PLACEMENT"
export const RW_SELECTED_POSITION_ID = "RW_SELECTED_POSITION_ID"
export const RW_SELECTED_SERIAL = "RW_SELECTED_SERIAL"
export const RW_SELECTED_BRAND = "RW_SELECTED_BRAND"
export const RS_REMOVE_SENSOR = "RS_REMOVE_SENSOR"
export const RS_UPDATE_BUNDLE_TO_DATABASE = "RS_UPDATE_BUNDLE_TO_DATABASE"
export const RS_REMOVE_SENSOR_FROM_APP = "RS_REMOVE_SENSOR_FROM_APP"

// Add Sensor
export const AS_ADD_DEVICE_TO_APP = "AS_ADD_DEVICE_TO_APP"
export const AS_UPDATE_BUNDLE_TO_DATABASE = "AS_UPDATE_BUNDLE_TO_DATABASE"
export const AS_SET_UP_SENSOR = "AS_SET_UP_SENSOR"
export const AS_DOWNLOAD_APP = "AS_DOWNLOAD_APP"
export const AS_CREATE_ACCOUNT = "AS_CREATE_ACCOUNT"
export const AS_ADD_INBOX_SENSOR = "AS_ADD_INBOX_SENSOR"
export const AS_SET_UP_KESTREL_SENSOR = "AS_SET_UP_KESTREL_SENSOR"
export const AS_DOWNLOAD_KESTREL_APP = "AS_DOWNLOAD_KESTREL_APP"
export const AS_ADD_KESTREL_DEVICE_TO_APP = "AS_ADD_KESTREL_DEVICE_TO_APP"
export const AS_DISABLE_KESTREL_AUTOCONNECT = "AS_DISABLE_KESTREL_AUTOCONNECT"
export const AS_SET_LOG_KESTREL_INTERVAL = "AS_SET_LOG_KESTREL_INTERVAL"

// Change House
export const CH_COLLECT_DATA = "CH_COLLECT_DATA"
export const CH_REMOVE_PARTICIPANT_PLACEMENT = "CH_REMOVE_PARTICIPANT_PLACEMENT"
export const CH_EXTRACT_LOCATIONS_COORDINATES = "CH_EXTRACT_LOCATIONS_COORDINATES"
export const CH_ADD_LOCATION_TO_DATABASE = "CH_ADD_LOCATION_TO_DATABASE"
export const CH_PARTICIPANT_PLACEMENT = "CH_PARTICIPANT_PLACEMENT"

export const CH_REMOVE_SENSOR_PLACEMENT_BASE = "CH_REMOVE_SENSOR_PLACEMENT_BASE"

export const getCHRSCheckBoxId = (positionID) => CH_REMOVE_SENSOR_PLACEMENT_BASE + "__" + positionID




export const ASHP_ADD_PLACEMENT_BASE = "ASHP_ADD_PLACEMENT_BASE"

export const getASHPCheckBoxId = (positionID) => ASHP_ADD_PLACEMENT_BASE + "__" + positionID



export const BUNDLE_SET_UP_CHECKBOXES = [
    GA_SET_UP_GOOGLE_ACCOUNT,
    FTB_DOWNLOAD_APPLICATION,
    FTB_CREATE_NEW_ACCOUNT,
    FTB_SET_TIME_ZONE,
    FTB_SET_UP_DEVICE,
    AWP_GET_WEARABLE_SERIAL,
    AWP_LINK_WEARABLE_SERIAL,
    FTB_SET_API,
    FTB_GO_TO_AUTHORIZATION_PAGE,
    FTB_COPY_TOKEN_AND_REQUEST,
    FTB_TEST_TOKEN,
    GA_ADD_EMAIL_TO_DATABASE,
    APPD_LINK_PHONE_SERIAL,
    TA_SET_UP_TRACKER_APP,
    TA_DOWNLOAD_TACKER_APP,
    TA_LOCATION_PERMISSIONS,
    TA_GET_PHONE_SERIAL,
    TA_ACTIVATE_TRACKING,
    TA_TURN_ON_LOCATION,
    TA_CONFIGURE_BATTERY,
    GAF_GROUP_APPS_IN_FOLDER,
    TB_TAG_BUNDLE,
    BD_ADD_BUNDLE_TO_DATABASE
]

export const BUNDLE_SET_UP_GOVEE_CHECKBOXES = [
    SUG_SET_UP_SENSOR,
    SUG_DOWNLOAD_APP,
    SUG_CREATE_ACCOUNT,
    SUG_ADD_DEVICE_TO_APP,
    SUG_ADD_INBOX_SENSOR,
]

export const BUNDLE_SET_UP_KESTREL_CHECKBOXES = [
    SUK_SET_UP_SENSOR,
    SUK_DOWNLOAD_APP,
    SUK_ADD_DEVICE_TO_APP,
    SUK_DISABLE_AUTOCONNECT,
    SUK_SET_LOG_INTERVAL,
]




export const BUNDLE_DEPLOYMENT_CHECKBOXES = [
    APT_ADD_PARTICIPANT_TO_DATABASE,
    APPD_PHONE_PLACEMENT,
    AWP_WEARABLE_PLACEMENT,
    ELC_EXTRACT_LOCATIONS_COORDINATES,
    ALD_ADD_LOCATION_TO_DATABASE,
    APAR_PARTICIPANT_PLACEMENT,
    LTA_RESTART_TRACKING_APP,
    LTA_TURN_ON_LOCATION,
    IS_INSERT_SIM,
    ED_EXPLAIN_FITBIT,
    ED_EXPLAIN_DUTIES,
    GD_SHOW_PHONE_PIN,
    GD_GIVE_DEVICES,
    CC_COLLECT_DATA,
    PIF_AMINISTER_INTAKE_FORM
]


// Data Collection checkboxes
export const GD_SYNC_DEVICES = "GD_SYNC_DEVICES"
export const GD_SEND_EMAIL = "GD_SEND_EMAIL"
export const GS_COLLECT_SENSOR_DATA = "GD_COLLECT_SENSOR_DATA"


export const KS_COLLECT_SENSOR_DATA = "KS_COLLECT_SENSOR_DATA"
export const KD_SYNC_DEVICES = "KD_SYNC_DEVICES"
export const KD_SEND_EMAIL = "KD_SEND_EMAIL"

export const MXHOBO_COLLECT_SENSOR_DATA = "MXHOBO_COLLECT_SENSOR_DATA"
export const MXHOBO_SYNC_DEVICES = "MXHOBO_SYNC_DEVICES"
export const MXHOBO_DOWNLOAD_DATA = "MXHOBO_DOWNLOAD_DATA"
export const MXHOBO_SEND_EMAIL = "MXHOBO_SEND_EMAIL"

export const WD_SYNC_WEARABLE_DATA = "WD_SYNC_WEARABLE_DATA"
export const WD_CHECK_HEATRATE = "WD_CHECK_HEATRATE"
export const LD_COLLECT_LOCATION_DATA = "LD_COLLECT_LOCATION_DATA"
export const LD_EXPORT_DATA = "LD_EXPORT_DATA"
export const LD_RESET_TRACKING = "LD_RESET_TRACKING"
export const LD_CLOSE_APPLICATION = "LD_CLOSE_APPLICATION"
export const LD_TURN_ON_LOCATION = "LD_TURN_ON_LOCATION"

export const PS_ADMINISTER_PERCEPTUAL_SURVEY = "PS_ADMINISTER_PERCEPTUAL_SURVEY"

export const DATA_COLLECT_CHECKBOXES = [

    WD_SYNC_WEARABLE_DATA,
    WD_CHECK_HEATRATE,
    LD_COLLECT_LOCATION_DATA,
    LD_EXPORT_DATA,
    LD_RESET_TRACKING,
    LD_TURN_ON_LOCATION,

    PS_ADMINISTER_PERCEPTUAL_SURVEY
]


export const DATA_COLLECT_GOVEE_CHECKBOXES = [
    GS_COLLECT_SENSOR_DATA,
    GD_SYNC_DEVICES,
    GD_SEND_EMAIL,
]


export const DATA_COLLECT_KESTREL_CHECKBOXES = [
    KS_COLLECT_SENSOR_DATA,
    KD_SYNC_DEVICES,
    KD_SEND_EMAIL,
]

export const DATA_COLLECT_HOBO_CHECKBOXES = [
    MXHOBO_COLLECT_SENSOR_DATA,
    MXHOBO_SYNC_DEVICES,
    MXHOBO_DOWNLOAD_DATA,
    MXHOBO_SEND_EMAIL,
]



export const DATA_COLLECT_DEVICE_CHECKBOXES = [
    GS_COLLECT_SENSOR_DATA,
    GD_SYNC_DEVICES,
    GD_SEND_EMAIL,
    KS_COLLECT_SENSOR_DATA,
    KD_SYNC_DEVICES,
    KD_SEND_EMAIL,
    MXHOBO_COLLECT_SENSOR_DATA,
    MXHOBO_SYNC_DEVICES,
    MXHOBO_DOWNLOAD_DATA,
    MXHOBO_SEND_EMAIL,

]




// Module Constants
export const MODULE_TEXT = "text"
export const MODULE_ON_CLICK = "onClick"
export const MODULE_TYPE = "type"




// Airtable Objects
export const AT_OBJECT_EMAIL_ACCOUNT = "AT_OBJECT_EMAIL_ACCOUNT"
export const AT_OBJECT_PHONE = "AT_OBJECT_PHONE"
export const AT_OBJECT_PHONE_PLACEMENT = "AT_OBJECT_PHONE_PLACEMENT"
export const AT_OBJECT_WEARABLE_PLACEMENT = "AT_OBJECT_WEARABLE_PLACEMENT"
export const AT_OBJECT_HOUSE = "AT_OBJECT_HOUSE"
export const AT_OBJECT_PARTICIPANT_PLACEMENT = "AT_OBJECT_PARTICIPANT_PLACEMENT"
export const AT_OBJECT_PARTICIPANT = "AT_OBJECT_PARTICIPANT"
export const AT_OBJECT_WEARABLE = "AT_OBJECT_WEARABLE"
export const AT_OBJECT_BUNDLE = "AT_OBJECT_BUNDLE"
export const AT_OBJECT_SENSOR = "AT_OBJECT_SENSOR"
export const AT_OBJECT_SENSORS_HOUSE_PLACEMENT = "AT_OBJECT_SENSORS_HOUSE_PLACEMENT"


// Issues
export const ISSUES_OBJECT = "ISSUES_OBJECT"
export const LAST_ISSUE_CHECK = "last_issue_check"


// Array Airtable Objects
export const AT_SET_OF_OBJECTS = "AT_SET_OF_OBJECTS"


// Received Files
export const AT_RECEIVED_SENSOR_FILES = "AT_RECEIVED_SENSOR_FILES"



export const AT_OBJECT_BASE_STRUCTURE = { [libCon.STATUS]: ATO_EMPTY, [libCon.ID]: null, [libCon.FIELDS]: {} }

export const AT_OBJECT_BASE_CONFIG = {
    [libCon.NECESSARY_IDS]: [],
    [libCon.OPTIONAL_IDS]: [],
    [libCon.COMPUTE_ON_UPLOAD]: {},
    [libCon.DERIVATE_IDS]: [],
    [libCon.SINGLE_DEPENDENCIES]: {},
    [libCon.SET_DEPENDENCIES]: {},
    [libCon.TABLE]: null,
    [libCon.SEARCH]: [],
    [libCon.IS_INSIDE_SET]: false
}


// Configuration Rules
// Objects that have no multiplicityu are outside the set (Phone, Wearable, Participant etc..)
// Objects that have multiplicity are inside the set (Sensors, Sensors Placement)
// If an object is outside the set:
// * Single dependencies should only reference objects outside sets
// * Multiple dependencies should only reference objects iniside set and only ids are allowed.
//
// If an object is inside the set:
// * Single dependencies can reference both type of elements. If element is inside set, will only affect the elemen at the same position (sensor placement and sensor).
//   If the element is outside the set, it will affect all elements (House and sensors)
// * Multiple dependencies for objects inside set is not allowed.


export const AT_OBJECTS_CONFIGS = {
    [AT_OBJECT_SENSOR]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_SERIAL],
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_SENSORS],
        [libCon.SEARCH]: [libCon.ATF_SERIAL],
        [libCon.IS_INSIDE_SET]: true
    },
    [AT_OBJECT_SENSORS_HOUSE_PLACEMENT]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_SENSOR_HOUSE_PLACEMENT],
        [libCon.NECESSARY_IDS]: [libCon.ATF_HOUSE, libCon.ATF_SENSOR, libCon.ATF_LATITUDE, libCon.ATF_LONGITUDE, libCon.ATF_ACCURACY],
        [libCon.SINGLE_DEPENDENCIES]: {
            [AT_OBJECT_HOUSE]: {
                [libCon.ID]: libCon.ATF_HOUSE,
                [libCon.FIELDS]: {
                    [libCon.ATF_NUMERIC_ID]: libCon.ATF_LOCATION_NUMERIC_ID
                }
            },
            [AT_OBJECT_SENSOR]: {
                [libCon.ID]: libCon.ATF_SENSOR,
                [libCon.FIELDS]: {
                    [libCon.ATF_SERIAL]: libCon.ATF_SENSOR_SERIAL
                }
            },
        },
        [libCon.IS_INSIDE_SET]: true,
        [libCon.SEARCH]: [libCon.ATF_TAG_CODE, libCon.ATF_TAG_COLOR, libCon.ATF_POSITION, libCon.ATF_LOCATION_NUMERIC_ID, libCon.ATF_SENSOR_SERIAL],
    },
    [AT_OBJECT_WEARABLE]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_WEARABLES],
        [libCon.SEARCH]: [libCon.ATF_SERIAL],
    },
    [AT_OBJECT_PHONE]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_SERIAL],
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_PHONES],
        [libCon.SEARCH]: [libCon.ATF_SERIAL],
    },
    [AT_OBJECT_PARTICIPANT]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_NAME, libCon.ATF_LAST_NAME, libCon.ATF_SEWA_ID, libCon.ATF_PHONE_NUMBER, libCon.ATF_TRADE],
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_PARTICIPANTS],
        [libCon.SEARCH]: [libCon.ATF_SEWA_ID],
    },
    [AT_OBJECT_EMAIL_ACCOUNT]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_EMAIL, libCon.ATF_PASSWORD, libCon.ATF_DROPBOX_PASSWORD, libCon.ATF_GOVEE_PASSWORD, libCon.ATF_FITBIT_TOKEN, libCon.ATF_FITBIT_CLIENT_ID, libCon.ATF_FITBIT_REQUEST_ID, libCon.ATF_FITBIT_TOKEN_EXPIRY_DATE],
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_EMAILS],
        [libCon.SEARCH]: [libCon.ATF_EMAIL],
    },
    [AT_OBJECT_PHONE_PLACEMENT]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_TAG_CODE, libCon.ATF_TAG_COLOR, libCon.ATF_PHONE, libCon.ATF_PARTICIPANT],
        [libCon.SINGLE_DEPENDENCIES]: {
            [AT_OBJECT_PHONE]: {
                [libCon.ID]: libCon.ATF_PHONE
            },
            [AT_OBJECT_PARTICIPANT]: {
                [libCon.ID]: libCon.ATF_PARTICIPANT
            }
        },
        [libCon.COMPUTE_ON_UPLOAD]: {
            [libCon.ATF_START_DATE]: () => nowInIndia(true)
        },
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_PHONE_PLACEMENTS],
        [libCon.SEARCH]: [libCon.ATF_PHONE_SERIAL]
    },
    [AT_OBJECT_WEARABLE_PLACEMENT]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_TAG_CODE, libCon.ATF_TAG_COLOR, libCon.ATF_WEARABLE, libCon.ATF_PARTICIPANT, libCon.ATF_ACCOUNT_EMAIL],
        [libCon.SINGLE_DEPENDENCIES]: {
            [AT_OBJECT_WEARABLE]: {
                [libCon.ID]: libCon.ATF_WEARABLE
            },
            [AT_OBJECT_PARTICIPANT]: {
                [libCon.ID]: libCon.ATF_PARTICIPANT
            },
            [AT_OBJECT_EMAIL_ACCOUNT]: {
                [libCon.ID]: libCon.ATF_ACCOUNT_EMAIL
            }
        },
        [libCon.COMPUTE_ON_UPLOAD]: {
            [libCon.ATF_START_DATE]: () => nowInIndia(true)
        },
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_WEARABLE_PLACEMENTS],
    },
    [AT_OBJECT_HOUSE]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_LATITUDE, libCon.ATF_LONGITUDE, libCon.ATF_ACCURACY, libCon.ATF_PARTICIPANT, libCon.ATF_AREA, libCon.ATF_ADDRESS],
        [libCon.SINGLE_DEPENDENCIES]: {
            [AT_OBJECT_PARTICIPANT]: {
                [libCon.ID]: libCon.ATF_PARTICIPANT
            }
        },
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_HOUSES],

    },
    [AT_OBJECT_PARTICIPANT_PLACEMENT]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_PARTICIPANT, libCon.ATF_HOUSE],
        [libCon.SINGLE_DEPENDENCIES]: {
            [AT_OBJECT_PARTICIPANT]: {
                [libCon.ID]: libCon.ATF_PARTICIPANT
            },
            [AT_OBJECT_HOUSE]: {
                [libCon.ID]: libCon.ATF_HOUSE
            }
        },
        [libCon.COMPUTE_ON_UPLOAD]: {
            [libCon.ATF_START_DATE]: () => nowInIndia(true)
        },
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_PARTICIPANTS_PLACEMENT],
    },
    [AT_OBJECT_BUNDLE]: {
        ...AT_OBJECT_BASE_CONFIG,
        [libCon.NECESSARY_IDS]: [libCon.ATF_PHONE_SERIAL, libCon.ATF_PHONE, libCon.ATF_ACCOUNT_EMAIL, libCon.ATF_WEARABLE, libCon.ATF_SENSORS, libCon.ATF_TAG_CODE, libCon.ATF_TAG_COLOR],
        [libCon.SINGLE_DEPENDENCIES]: {
            AT_OBJECT_PHONE: {
                [libCon.ID]: libCon.ATF_PHONE,
                [libCon.FIELDS]: {
                    [libCon.ATF_SERIAL]: libCon.ATF_PHONE_SERIAL
                }
            },
            AT_OBJECT_WEARABLE: {
                [libCon.ID]: libCon.ATF_WEARABLE
            },
            AT_OBJECT_EMAIL_ACCOUNT: {
                [libCon.ID]: libCon.ATF_ACCOUNT_EMAIL
            },
        },
        [libCon.SET_DEPENDENCIES]: {
            [AT_OBJECT_SENSOR]: {
                [libCon.ID]: libCon.ATF_SENSORS
            }
        },
        [libCon.TABLE]: libCon.CONFIG[libCon.AT_TABLE_BUNDLES],
        [libCon.DERIVATE_IDS]: [libCon.ATF_PHONE_SERIAL],
        [libCon.SEARCH]: [libCon.ATF_PHONE_SERIAL],
    },

}


// Store Support Values
export const FITBIT_URL = 'fitbit_url'
export const FITBIT_API_OK = 'fitbit_api_ok'
export const FITBIT_TOKEN = "fitbit_token"
export const FITBIT_REQUEST_ID = "fitbit_request_id"
export const LINK_CLICKED = "__LINK_CLICKED__"

// Perceptual Survey
export const HF_CONNECTION_SENDING_ANSWERS_STATUS = "HF_CONNECTION_SENDING_ANSWERS_STATUS"
export const HEALTH_FORM_CONFIGURATION_STATUS = "HEALTH_FORM_CONFIGURATION_STATUS"
export const HEALTH_FORM_ANSWERED_QUESTIONS = "HEALTH_FORM_ANSWERED_QUESTIONS"

// Participant Intake Form
export const PIF_CONNECTION_SENDING_ANSWERS_STATUS = "PIF_CONNECTION_SENDING_ANSWERS_STATUS"
export const PARTICIPANT_INTAKE_FORM_CONFIGURATION_STATUS = "PARTICIPANT_INTAKE_FORM_CONFIGURATION_STATUS"
export const PARTICIPANT_INTAKE_FORM_ANSWERED_QUESTIONS = "PARTICIPANT_INTAKE_FORM_ANSWERED_QUESTIONS"

// House Intake Form
export const HIF_CONNECTION_SENDING_ANSWERS_STATUS = "HIF_CONNECTION_SENDING_ANSWERS_STATUS"
export const HOUSE_INTAKE_FORM_CONFIGURATION_STATUS = "HOUSE_INTAKE_FORM_CONFIGURATION_STATUS"
export const HOUSE_INTAKE_FORM_ANSWERED_QUESTIONS = "HOUSE_INTAKE_FORM_ANSWERED_QUESTIONS"

// Collection Report
export const COLLECTION_REPORT_DURATION = "collection_report_duration"
export const COLLECTION_REPORT_STATUS = "collection_report_status"
export const COLLECTION_REPORT_NOTES = "collection_report_notess"
export const COLLECTION_REPORT_LAST_UPLOAD = "collection_last_upload"

// Logged in
export const LAST_LOG_IN = "last_log_in"
export const SHOW_EMPTY_PAGE = "show_empty_page"


// Orientations
export const HORIZONTAL = "horizontal"
export const VERTICAL = "vertical"
