// Health functions
import * as locCon from '../LocalConstants';
import * as libCon from '../community-hats-js-library/Constants';
import { getATOField, getATOId, getStoredValue } from '../hooks/StoreHooks';
import { formatExport, formatISOCustom } from '../community-hats-js-library/utils/dateFunctions';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';


export const buildPerceptualSurveyMeta = () => {
    return (
        {
            [libCon.SURVEY_EXPORT_PARTICIPANT_ID]: getATOId(locCon.AT_OBJECT_PARTICIPANT),
            [libCon.SURVEY_EXPORT_SEWA_ID]: getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID),
            [libCon.SURVEY_EXPORT_PHONE_SERIAL]: getATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_SERIAL),
            [libCon.SURVEY_EXPORT_DATETIME]: formatISOCustom(new Date()),
            [libCon.SURVEY_EXPORT_LOCALE]: getStoredValue(locCon.STORAGE_CURRENT_LOCAL)
        }
    )

}

export const buildParticipantIntakeFormMeta = () => {
    return (
        {
            [libCon.SURVEY_EXPORT_PARTICIPANT_ID]: getATOId(locCon.AT_OBJECT_PARTICIPANT),
            [libCon.SURVEY_EXPORT_SEWA_ID]: getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID),
            [libCon.SURVEY_EXPORT_PHONE_SERIAL]: getATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_SERIAL),
            [libCon.SURVEY_EXPORT_DATETIME]: formatISOCustom(new Date()),
            [libCon.SURVEY_EXPORT_LOCALE]: getStoredValue(locCon.STORAGE_CURRENT_LOCAL),

        }
    )

}


export const buildHouseIntakeFormMeta = () => {
    return (
        {
            [libCon.SURVEY_EXPORT_PHONE_SERIAL]: getATOField(locCon.AT_OBJECT_PHONE, libCon.ATF_SERIAL),
            [libCon.SURVEY_EXPORT_DATETIME]: formatISOCustom(new Date()),
            [libCon.SURVEY_EXPORT_LOCALE]: getStoredValue(locCon.STORAGE_CURRENT_LOCAL),
            [libCon.SURVEY_EXPORT_HOUSE_NUMBER]: getATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_NUMERIC_ID),
            [libCon.SURVEY_EXPORT_HOUSE_ID]: getATOId(locCon.AT_OBJECT_HOUSE),

        }
    )

}

export const buildSurveyExport = (surveyStructure, encoder, buildMeta) => {

    if (surveyStructure === null || surveyStructure === undefined)
        return null

    // Answers
    const answers = surveyStructure[libCon.SURVEY_STRUCTURE].reduce((res, q) => { return Object.assign(res, { [q[libCon.SURVEY_QUESTION_ID]]: getStoredValue(encoder(q[libCon.SURVEY_QUESTION_ID])) }) }, {})


    let sewaId = getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)
    if (isNullOrUndefined(sewaId))
        sewaId = libCon.MISSING

    // Meta
    let surveyMeta = buildMeta()
    let meta = {
        ...surveyStructure[libCon.SURVEY_METADATA],
        ...surveyMeta
    }

    const response = {
        [libCon.SURVEY_EXPORT_CONFIGURATION]: surveyStructure,
        [libCon.SURVEY_EXPORT_ANSWERS]: answers,
        [libCon.SURVEY_METADATA]: meta
    }

    return (response)


}

export const buildSurveyFilename = () => {

    let sewa_id = getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)
    if (sewa_id === null)
        sewa_id = libCon.MISSING

    return (`${sewa_id}__${formatExport(new Date())}.json`)

}

export const buildHouseSurveyFilename = () => {

    let house = getATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_NUMERIC_ID)
    if (house === null)
        house = libCon.MISSING

    return (`${house}__${formatExport(new Date())}.json`)

}

export const encodeSurveyId = (prefix, id) => {
    return (`${prefix}${id}`)
}

export const decodeSurveyId = (prefix, id) => {
    if (id === null || id === undefined)
        return id
    return (`${id}`.replace(prefix, ""))
}


export const encodeHealthFormId = (id) => {
    return encodeSurveyId(libCon.PERCEPTUAL_SURVEY_PREFIX, id)
}

export const encodeParticipantIntakeFormId = (id) => {
    return encodeSurveyId(libCon.PARTICIPANT_INTAKE_FORM_PREFIX, id)
}

export const encodeHouseIntakeFormId = (id) => {
    return encodeSurveyId(libCon.HOUSE_INTAKE_FORM_PREFIX, id)
}


