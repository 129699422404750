import React, { useEffect, useState } from 'react'
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage, RefLanguageAndLocale } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import CollectionStatus from '../elements/CollectionStatus';
import { SimpleCopyField } from '../elements/SimpleStoreCopyField';
import { getPendingIssues, RefIssuesStatus, RefPendingIssues, refreshIssues, updateIssue } from '../hooks/BundleDeviceHooks';
import { showErrorDialogByCode } from '../utils/dialogFunctions';
import { showNotification } from '../utils/generalFunctions';
import CustomSpin from '../elements/CustomSpin';
import { RefATObjectId, RefStoredValue, StateStoredValue } from '../hooks/StoreHooks';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { formatDistanceShort, formatToIndiaTimeReadable } from '../community-hats-js-library/utils/dateFunctions';
import { CustomPrimaryButtonWithDisability } from '../elements/CustomButton';
import { Tag } from 'antd';
import { SimpleStoreCopyTextAreaFromId } from '../elements/SimpleStoreCopyTextArea';
import { getIssueColumnsByLocale, markIssueAsReadyForRevision } from '../community-hats-js-library/utils/airtableFunctions';


export const CustomTag = ({ tagText, i }) => {
    return (<Tag color={libCon.TAG_COLORS[i % libCon.TAG_COLORS.length]}
        style={{
            marginLeft: "3px",
            marginRight: "3px"
        }}>
        {tagText}
    </Tag>)
}


const SingleIssueStatusViewer = ({ issueId, issue, position, notesId }) => {

    const [i18n, locale] = RefLanguageAndLocale()

    const [descriptionKey, setDescriptionKey] = useState(libCon.ATF_DESCRIPTION)
    const [actionKey, setActionKey] = useState(libCon.ATF_ACTION)

    useEffect(() => {

        const [newDescriptionKey, newActionKey] = getIssueColumnsByLocale(locale)
        setDescriptionKey(newDescriptionKey)
        setActionKey(newActionKey)

    }, [locale])


    const [notes, setNotes] = StateStoredValue(notesId)

    const [isSending, setIsSending] = useState(false)

    const localSenddForRevision = async () => {
        setIsSending(true)



        const [response, newIssue] = await markIssueAsReadyForRevision(issue, notes, libCon.CH_MANAGERE_APP)

        if (response === libCon.OK) {

            setNotes(null)
            updateIssue(issueId, newIssue[issueId])
            showNotification(i18n.t("issueSentForRevisionSuccessfully"))
        }
        else
            showErrorDialogByCode(response)

        setIsSending(false)

    }


    return (
        <div className='verticalSection' style={{ backgroundColor: "var(--background-color-2)", marginTop: "1vh", marginBottom: "1vh", width: "95%", padding: "1vh" }}>
            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("issue") + " " + position}</CustomText>
            <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("tags")}</CustomText>
            <div className='horizontalSection'>
                {issue[libCon.ATF_TAGS].map((t, i) => <CustomTag key={i} tagText={t} i={i} />)}
            </div>

            <CustomText type={locCon.ELEMENT_TITLE_TEXT} style={{ marginTop: "2vh" }}>{i18n.t("description")}</CustomText>
            <CustomText type={locCon.INFO_TEXT}>{issue[descriptionKey]}</CustomText>

            <CustomText type={locCon.ELEMENT_TITLE_TEXT} style={{ marginTop: "2vh" }}>{i18n.t("action")}</CustomText>
            <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: "2vh" }}>{issue[actionKey]}</CustomText>


            <SimpleStoreCopyTextAreaFromId title={i18n.t("notes")} valueID={notesId} />


            {isSending
                ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                : <CustomPrimaryButtonWithDisability onClick={() => localSenddForRevision()} isDisabled={isNullOrUndefined(notes)} title={i18n.t("incomplete")} description={i18n.t("pleaseIncludeNotes")}>{i18n.t("sendForRevision")}</CustomPrimaryButtonWithDisability>
            }



        </div>
    )

}



function IssuesStatusViewer({ includeTitle = true }) {


    const i18n = RefLanguage()

    const participantId = RefATObjectId(locCon.AT_OBJECT_PARTICIPANT)


    const pendingIssues = RefPendingIssues()
    const lastIssueCheck = RefStoredValue(locCon.LAST_ISSUE_CHECK)

    const [issueNumbers, setIssueNumbers] = useState({})

    const [status, message] = RefIssuesStatus()


    const [isSynching, setisSynching] = useState(false)


    const refreshRecords = async () => {
        setisSynching(true)
        let response = await refreshIssues()

        if (response !== libCon.OK)
            showErrorDialogByCode(response)
        else
            showNotification(i18n.t("refreshComplete"))

        setisSynching(false)
    }


    useEffect(() => {
        let currentPendingIssues = getPendingIssues()
        setIssueNumbers(Object.fromEntries(Object.keys(currentPendingIssues).map((k, i) => [k, i + 1])))
    }, [lastIssueCheck])




    return (
        <div className='verticalSection'>
            {includeTitle ? <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("issues")}</CustomText> : <div></div>}

            <CollectionStatus status={status} message={message} />


            <SimpleCopyField title={i18n.t("lastRefreshed")} value={isNullOrUndefined(lastIssueCheck) ? i18n.t("never") : <>{`${formatToIndiaTimeReadable(lastIssueCheck, true, false)}`}<br />{`(${formatDistanceShort(lastIssueCheck)})`}</>} enableCopy={false} />

            {
                isSynching
                    ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                    : <CustomPrimaryButtonWithDisability isDisabled={isNullOrUndefined(participantId)} title={i18n.t("incompleteDeployment")} description={i18n.t("addParticipant")} onClick={() => refreshRecords()}>{i18n.t("refresh")}</CustomPrimaryButtonWithDisability>
            }

            {
                Object.keys(pendingIssues).length > 0
                    ? <div className='verticalSection'>
                        <CustomText type={locCon.ELEMENT_TITLE_TEXT} style={{ marginTop: "1vh" }}>{i18n.t("pendingIssues")}</CustomText>
                        {
                            Object.keys(pendingIssues).map((k, i) => <SingleIssueStatusViewer key={k} issueId={k} notesId={k + libCon.ATF_NOTES} issue={pendingIssues[k]} position={k in issueNumbers ? issueNumbers[k] : ""} />)
                        }
                    </div>
                    : <div></div>

            }


        </div>
    )
}

export default IssuesStatusViewer