import Header from '../components/Header';
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import { SensorStatusSetViewer } from '../components/SensorSetViewer';
import PhoneStatusViewer from '../components/PhoneStatusViewer';
import WearableStatusViewer from '../components/WearableStatusViewer';
import PerceptualSurveyStatusViewer from '../components/PerceptualSurveyStatusViewer';


export function BundleCheckStatus() {

  const i18n = RefLanguage()

  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={false} />
        <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("checkBundleStatus")}</CustomText>
        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToCheck")}</CustomText>
        <div className="horizontalLine" />


        {/* Sensor */}
        <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("sensors")}</CustomText>
        <SensorStatusSetViewer />
        <div className='horizontalLine' />

        {/* Phone */}
        <PhoneStatusViewer />
        <div className='horizontalLine' />

        {/* Wearable */}
        <WearableStatusViewer />
        <div className='horizontalLine' />

        {/* Perceptual Survey */}
        <PerceptualSurveyStatusViewer />
        <div className='horizontalLine' />


      </div>
    </div>
  );
}

export default BundleCheckStatus


