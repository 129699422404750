// Health functions
import * as libCon from '../Constants';
import { isNullOrUndefined } from './generalFunctions';

export const convertToCelsius = (x) => {
  return (5 / 9) * (x - 32)
}


export const bloodPressureCheckFunction = (sys, dia) => {

  if (sys === libCon.NA && dia === libCon.NA)
    return true

  if (sys === libCon.NA || dia === libCon.NA)
    return false

  if (isNullOrUndefined(sys) || isNullOrUndefined(dia))
    return false

  let numSys = Number(sys)
  let numDia = Number(dia)


  if (isNaN(numSys) || isNaN(numDia))
    return false

  if (numSys <= 0 || numSys > 300 || numDia <= 0 || numDia > 300)
    return false

  return true

}



export const oralTemperatureCheckFunction = (val) => {
  if (val === libCon.NA)
    return (true)

  if (val === null || val === undefined)
    return false

  let num = Number(val)

  if (isNaN(num))
    return false

  if (num <= 27 || (num > 45 && num <= 80.6) || num > 113)
    return false

  return true

}

export const oralTemperatureWarningFunction = (val) => {
  if (val === libCon.NA)
    return (true)

  let num = Number(val)

  if (isNaN(num))
    return false

  if ((num < 36 && num > 27) || (num > 38.8 && num <= 45) || (num < 96.9 && num > 80.6) || (num > 101.84 && num <= 113))
    return false

  return true

}

export const bloodPressureWarningFunction = (sys, dia) => {
  return true
}


export const oralTemperatureNeedsThird = (t1, t2) => {

  const maxDif = 0.3

  if (t1 > 60)
    t1 = convertToCelsius(t1)

  if (t2 > 60)
    t2 = convertToCelsius(t2)

  const array = [t1, t2]
  if (array.every(m => m === libCon.NA))
    return false

  if (array.some(m => m === libCon.NA))
    return true

  if (Math.abs(t1 - t2) >= maxDif)
    return true

  return false
}


export const bloodPressureNeedsThird = (s1, d1, s2, d2) => {

  const maxDif = 6
  const array = [s1, d1, s2, d2]
  if (array.every(m => m === libCon.NA))
    return false

  if (array.some(m => m === libCon.NA))
    return true

  if (Math.abs(s1 - s2) >= maxDif || Math.abs(d1 - d2) >= maxDif)
    return true

  return false
}