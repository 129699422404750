import React, { useEffect, useState } from 'react'
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants';
// import * as genFun from '../community-hats-js-library/utils/generalFunctions';
import Header from '../components/Header';
import CustomText from '../elements/CustomText';
import CustomButton, { CustomButtonWithWarining } from '../elements/CustomButton';
import { addNewPositionToATOSet, deletePositionToATOSet, RefKeysOfATOSet, RefStoredBooleanValue, setMultipleValues, setStoredValue } from '../hooks/StoreHooks';
import CheckboxGroup from '../elements/CheckboxGroup';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import CompletionStatusText from '../elements/CompletionStatusText';
import { IsGovee, RefIsKestrel, RefPositionIdMissingPlacement } from '../hooks/BundleDeviceHooks';
import { SensorViewer } from '../components/SensorSetViewer';
import { SimpleCopyField, SimpleCopyFieldFromATO, SimpleCopyFieldFromATOInsideSet } from '../elements/SimpleStoreCopyField';
import SimpleLink from '../elements/SimpleLink';
import ActivationCodeLoader from '../components/ActivationCodeLoader';
import { SensorPlacementViewer } from '../components/SensorPlacementSetViewer';
import { SyncATOButton } from '../elements/SyncATOButton';


const initialCheckboxes = [locCon.AS_UPDATE_BUNDLE_TO_DATABASE]

function AddSensor() {

    const i18n = RefLanguage()


    const keys = RefKeysOfATOSet()

    const externalPositionId = RefPositionIdMissingPlacement()

    const [positionId, setPositionId] = useState(null)

    useEffect(() => {

        // Only Updates if external is not Null
        if (!isNullOrUndefined(externalPositionId))
            setPositionId(externalPositionId)


    }, [externalPositionId])



    // Govee
    const isGovee = IsGovee(positionId)
    const alreadySetUpGovee = RefStoredBooleanValue(locCon.SUG_SET_UP_SENSOR)



    // Kestrel
    const isKestrel = RefIsKestrel(positionId)
    const alreadySetUpKestrel = RefStoredBooleanValue(locCon.SUK_SET_UP_SENSOR)


    // TODO
    // Use the checkboxes from setup
    // Think of what should get restarted on restart process
    //    const finishSetUpKestrel = RefStoredBooleanValue(locCon.AS_SET_UP_KESTREL_SENSOR)
    // const finishSetUpGovee = RefStoredBooleanValue(locCon.AS_SET_UP_KESTREL_SENSOR)


    const [allCheckboxes, setAllCheckboxes] = useState(initialCheckboxes)


    useEffect(() => {

        let newCheckboxes = [...initialCheckboxes]
        if (isGovee) {
            newCheckboxes = [...newCheckboxes, locCon.AS_ADD_DEVICE_TO_APP]

            if (!alreadySetUpGovee)
                newCheckboxes = [...newCheckboxes,
                locCon.AS_SET_UP_SENSOR,
                locCon.AS_DOWNLOAD_APP,
                locCon.AS_CREATE_ACCOUNT,
                locCon.AS_ADD_INBOX_SENSOR]

        }


        if (isKestrel) {

            newCheckboxes = [...newCheckboxes,
            locCon.AS_SET_UP_KESTREL_SENSOR,
            locCon.AS_ADD_KESTREL_DEVICE_TO_APP,
            locCon.AS_DISABLE_KESTREL_AUTOCONNECT,
            locCon.AS_SET_LOG_KESTREL_INTERVAL,
            ]

            if (!alreadySetUpKestrel)
                newCheckboxes = [...newCheckboxes,
                locCon.AS_DOWNLOAD_KESTREL_APP,
                ]


        }


        if (!isNullOrUndefined(positionId))
            newCheckboxes = [...newCheckboxes, locCon.getASCheckBoxId(positionId), locCon.getASHPCheckBoxId(positionId)]


        setAllCheckboxes(newCheckboxes)




    }, [positionId, isGovee, isKestrel, alreadySetUpGovee, alreadySetUpKestrel])




    const restartProcess = () => {

        let checkBoxValues = {
        }

        let newCheckboxes = [...initialCheckboxes]
        if (isGovee) {
            newCheckboxes = [...newCheckboxes, locCon.AS_ADD_DEVICE_TO_APP]

            if (!alreadySetUpGovee)
                newCheckboxes = [...newCheckboxes,
                locCon.AS_SET_UP_SENSOR,
                locCon.AS_DOWNLOAD_APP,
                locCon.AS_CREATE_ACCOUNT,
                locCon.AS_ADD_INBOX_SENSOR]


        }


        if (isKestrel) {

            newCheckboxes = [...newCheckboxes,
            locCon.AS_SET_UP_KESTREL_SENSOR,
            locCon.AS_ADD_KESTREL_DEVICE_TO_APP,
            locCon.AS_DISABLE_KESTREL_AUTOCONNECT,
            locCon.AS_SET_LOG_KESTREL_INTERVAL,
            ]

            if (!alreadySetUpKestrel)
                newCheckboxes = [...newCheckboxes,
                locCon.AS_DOWNLOAD_KESTREL_APP,
                ]


        }

        newCheckboxes.forEach(k => checkBoxValues[k] = false)

        setMultipleValues(checkBoxValues)

        if (!isNullOrUndefined(externalPositionId))
            deletePositionToATOSet(externalPositionId)


    }


    return (
        <div className="mainAppContainer">
            <div className='pageContainer'>

                <Header long={false} />
                <CustomText type={locCon.MODULE_TITLE_TEXT}>{i18n.t("removeSensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("stepsToAddSensor")}</CustomText>
                <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("noteAddSensor")}</CustomText>

                <CustomButtonWithWarining type={locCon.DANGER_BUTTON} title={i18n.t("restartProcess")} description={i18n.t("restartProcessLong")} okButtonText={i18n.t("restart")} onClick={() => restartProcess()}>{i18n.t("restartProcess")}</CustomButtonWithWarining>

                <div className='horizontalLine' />

                {
                    isNullOrUndefined(positionId) || !keys.includes(positionId)
                        ? <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => { restartProcess(); addNewPositionToATOSet() }}>{i18n.t("addSensorToBundle")}</CustomButton>
                        : <div className='verticalSection'>
                            <SensorViewer key={positionId} positionId={positionId} positionNumber={1} />

                            <div className="horizontalLine" />

                            {/* Sensor Setup*/}
                            {/* Govee*/}
                            {
                                isGovee
                                    ? !alreadySetUpGovee
                                        ? <>
                                            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("setUpNewGovee")}</CustomText>
                                            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("setUpNewGoveeSteps")}</CustomText>

                                            <CheckboxGroup
                                                mainCheckbox={{ [libCon.ID]: locCon.AS_SET_UP_SENSOR, [libCon.TEXT]: i18n.t("setUpGovees") }}
                                                checkBoxArray={[
                                                    {
                                                        [libCon.ID]: locCon.AS_DOWNLOAD_APP,
                                                        [libCon.TEXT]: i18n.t("downloadGoveeApp"),
                                                        [libCon.CONTAINER]: <div>
                                                            <SimpleLink text={i18n.t("goveeApp")} link={"https://play.google.com/store/apps/details?id=com.govee.home&hl=en&gl=US"} />
                                                        </div>
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_CREATE_ACCOUNT,
                                                        [libCon.TEXT]: i18n.t("createGoveeAccount"),
                                                        [libCon.CONTAINER]: <div>
                                                            <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_EMAIL} title={i18n.t("goveeEmail")} processFunction={(val) => val === null || val === undefined ? null : val} />
                                                            <SimpleCopyFieldFromATO atoId={locCon.AT_OBJECT_EMAIL_ACCOUNT} fieldId={libCon.ATF_GOVEE_PASSWORD} title={i18n.t("goveePassword")} />
                                                        </div>
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_ADD_DEVICE_TO_APP,
                                                        [libCon.TEXT]: i18n.t("addSensorsToApp"),
                                                        [libCon.CONTAINER]: <div className="verticalSection">
                                                            <CustomText type={locCon.INFO_TEXT}>{i18n.t("nameGoveeSensors")}</CustomText>
                                                            <SimpleCopyFieldFromATOInsideSet positionId={positionId} atoId={locCon.AT_OBJECT_SENSOR} fieldId={libCon.ATF_SERIAL} />

                                                        </div>
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_ADD_INBOX_SENSOR,
                                                        [libCon.TEXT]: i18n.t("addInboxSensor"),
                                                        [libCon.CONTAINER]: <div>
                                                            <SimpleCopyField title={i18n.t("sensorInbox")} value={libCon.SENSOR_INBOX_EMAIL} />
                                                            <ActivationCodeLoader />
                                                        </div>
                                                    },
                                                ]}
                                            />
                                            <div className="horizontalLine" />

                                        </>
                                        : <>
                                            <CheckboxGroup mainCheckbox={{
                                                [libCon.ID]: locCon.AS_ADD_DEVICE_TO_APP,
                                                [libCon.TEXT]: i18n.t("addSensorsToApp"),
                                                [libCon.CONTAINER]: <div className="verticalSection">
                                                    <CustomText type={locCon.INFO_TEXT}>{i18n.t("nameGoveeSensors")}</CustomText>
                                                    <SimpleCopyFieldFromATOInsideSet positionId={positionId} atoId={locCon.AT_OBJECT_SENSOR} fieldId={libCon.ATF_SERIAL} />
                                                </div>
                                            }} />
                                            <div className="horizontalLine" />

                                        </>
                                    : <div></div>
                            }

                            {/* Sensor Setup*/}
                            {/* Kestrel*/}
                            {
                                isKestrel
                                    ? !alreadySetUpKestrel
                                        ? <>
                                            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("setUpNewKestrel")}</CustomText>
                                            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("setUpNewKestrelSteps")}</CustomText>

                                            <CheckboxGroup
                                                mainCheckbox={{ [libCon.ID]: locCon.AS_SET_UP_KESTREL_SENSOR, [libCon.TEXT]: i18n.t("setUpKestrel") }}
                                                checkBoxArray={[
                                                    {
                                                        [libCon.ID]: locCon.AS_DOWNLOAD_KESTREL_APP,
                                                        [libCon.TEXT]: i18n.t("downloadSensorApp"),
                                                        [libCon.CONTAINER]: <div>
                                                            <SimpleLink text={i18n.t("kestrelApp")} link={"https://play.google.com/store/apps/details?id=com.kestrelinstruments.kdex&hl=en&gl=US"} />
                                                        </div>
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_ADD_KESTREL_DEVICE_TO_APP,
                                                        [libCon.TEXT]: i18n.t("addSensorToApp")
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_DISABLE_KESTREL_AUTOCONNECT,
                                                        [libCon.TEXT]: i18n.t("disableAutoConnect")
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_SET_LOG_KESTREL_INTERVAL,
                                                        [libCon.TEXT]: i18n.t("setLogInterval")
                                                    },

                                                ]} />

                                            <div className="horizontalLine" />

                                        </>
                                        : <>
                                            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("setUpNewKestrel")}</CustomText>
                                            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("setUpNewKestrelSteps")}</CustomText>

                                            <CheckboxGroup
                                                mainCheckbox={{ [libCon.ID]: locCon.AS_SET_UP_KESTREL_SENSOR, [libCon.TEXT]: i18n.t("setUpKestrel") }}
                                                checkBoxArray={[
                                                    {
                                                        [libCon.ID]: locCon.AS_ADD_KESTREL_DEVICE_TO_APP,
                                                        [libCon.TEXT]: i18n.t("addSensorToApp")
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_DISABLE_KESTREL_AUTOCONNECT,
                                                        [libCon.TEXT]: i18n.t("disableAutoConnect")
                                                    },
                                                    {
                                                        [libCon.ID]: locCon.AS_SET_LOG_KESTREL_INTERVAL,
                                                        [libCon.TEXT]: i18n.t("setLogInterval")
                                                    },

                                                ]} />

                                            <div className="horizontalLine" />

                                        </>
                                    : <div></div>

                            }



                            {/* Add / Sync Bundle to database*/}
                            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("updateBundle")}</CustomText>
                            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("updateBundleText")}</CustomText>
                            <CheckboxGroup disableClick={true} mainCheckbox={{
                                [libCon.ID]: locCon.AS_UPDATE_BUNDLE_TO_DATABASE,
                                [libCon.TEXT]: i18n.t("updateBundle"),
                                [libCon.CONTAINER]: <SyncATOButton atoId={locCon.AT_OBJECT_BUNDLE} atoBehavior={libCon.ATO_BEHAVIOR_UPDATE_CREATE} onClickCleanup={(val) => setStoredValue(locCon.AS_UPDATE_BUNDLE_TO_DATABASE, val)} />
                            }} />

                            <div className="horizontalLine" />

                            <CustomText type={locCon.SECTION_TITLE_TEXT}>{i18n.t("deploySensor")}</CustomText>
                            <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("deploySensorText")}</CustomText>
                            <SensorPlacementViewer positionId={positionId} positionNumber={1} />



                            <div className="horizontalLine" />

                            <CompletionStatusText checkBoxIds={allCheckboxes} extraMessage={i18n.t("collectDataAndCheckSensors")} />


                        </div>
                }





                <div className="horizontalLine" />

            </div>
        </div>
    );
}

export default AddSensor