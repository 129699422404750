import React, { useEffect, useState } from 'react'
import { getATOId, RefATObject, RefATObjectInsideSet, RefATObjectInsideSetId, setATObject, setATObjectInsideSet } from '../hooks/StoreHooks'
import CustomButton, { CustomPrimaryButtonWithDisability } from './CustomButton'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import * as locGenFun from "../utils/generalFunctions"
import { syncRecord } from '../utils/airtableFunctions'
import { RefLanguage } from '../hooks/LanguageHooks'
import CustomSpin from './CustomSpin'
import { filterObjectByKeys, isNullOrUndefined, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions'
import CustomText from './CustomText'
import { RefBundleDevices, resetATObject, resetATObjectInsideSet } from '../hooks/BundleDeviceHooks'
import { CheckCircleTwoTone, ClockCircleOutlined, QuestionCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { endPlacement } from '../community-hats-js-library/utils/airtableFunctions'
import { NewIcon } from './CustomIcons'

export function SyncATOInsideSetButton({ positionId, atoId, atoBehavior, ...props }) {

    // Object
    const [status, id, fields] = RefATObjectInsideSet(positionId, atoId)


    return (<GenericSyncATOButton status={status}
        id={id}
        fields={fields}
        setObject={(newId, newFields) => setATObjectInsideSet(positionId, atoId, newId, newFields)}
        config={locCon.AT_OBJECTS_CONFIGS[atoId]}
        atoBehavior={atoBehavior} {...props} />)
}

export function SyncATOButton({ atoId, atoBehavior, ...props }) {


    // Object
    const [status, id, fields] = RefATObject(atoId)


    return (<GenericSyncATOButton status={status}
        id={id}
        fields={fields}
        setObject={(newId, newFields) => setATObject(atoId, newId, newFields)}
        config={locCon.AT_OBJECTS_CONFIGS[atoId]}
        atoBehavior={atoBehavior} {...props} />)
}

function GenericSyncATOButton({ status, id, fields, setObject, config, atoBehavior, customMessages = {}, becomeTextOnSuccess = true, onClickCleanup = (__) => true, isNew = false, forceDisable = false, forceDisabledText = null, checkDependencies = true }) {

    // Language
    const i18n = RefLanguage()

    // Button Behavior
    const [isDisabled, setIsDisabled] = useState(false)
    const [buttonText, setButtonText] = useState(() => "---")
    const [successText, setSuccessText] = useState(() => null)
    const [warningText, setWarningText] = useState(() => null)

    const [isSynching, setisSynching] = useState(() => false)

    const bundleDevices = RefBundleDevices()


    const syncATObject = async () => {


        setisSynching(true)
        let includedIds = [...config[libCon.NECESSARY_IDS], ...config[libCon.OPTIONAL_IDS]]



        // Removes derivates
        includedIds = includedIds.filter(k => !config[libCon.DERIVATE_IDS].includes(k))

        let finalFields = filterObjectByKeys(fields, includedIds)

        // Check for compute
        if ([libCon.ATO_BEHAVIOR_CREATE, libCon.ATO_BEHAVIOR_UPDATE_CREATE].includes(atoBehavior))
            Object.keys(config[libCon.COMPUTE_ON_UPLOAD]).forEach(k => finalFields[k] = config[libCon.COMPUTE_ON_UPLOAD][k]())


        let searchFields = config[libCon.SEARCH]
        let searchValues = !isNullOrUndefinedOrEmpty(config[libCon.SEARCH]) ? searchFields.map(f => fields[f]) : null
        let [response, message, newId, newFields] = await syncRecord({ tableId: config[libCon.TABLE], behavior: atoBehavior, id: id, fields: finalFields, searchField: searchFields, searchValue: searchValues })

        if (response === libCon.OK) {
            setObject(newId, newFields)
            locGenFun.showNotification(message)
        }
        else if (response in customMessages)
            locGenFun.showError(customMessages[response])
        else
            locGenFun.showError(message)

        onClickCleanup(response === libCon.OK)
        setisSynching(false)

    }

    useEffect(() => {

        switch (atoBehavior) {
            case libCon.ATO_BEHAVIOR_PULL:
                setButtonText(i18n.t("syncRecord"))
                setSuccessText(i18n.t("recordSynched"))
                break
            case libCon.ATO_BEHAVIOR_UPDATE:
                setButtonText(i18n.t("updateRecord"))
                setSuccessText(i18n.t("recordUpdated"))
                break

            case libCon.ATO_BEHAVIOR_CREATE:
                setButtonText(i18n.t("createRecord"))
                setSuccessText(i18n.t("recordInserted"))
                break

            case libCon.ATO_BEHAVIOR_UPDATE_CREATE:
                if (status === locCon.ATO_NEEDS_SYNCH) {
                    setButtonText(i18n.t("updateRecord"))
                    setSuccessText(i18n.t("recordUpdated"))
                }
                else {
                    setButtonText(i18n.t("createRecord"))
                    setSuccessText(i18n.t("recordInserted"))
                }
                break

            case libCon.ATO_BEHAVIOR_PULL_CREATE:

                setButtonText(i18n.t("syncRecord"))
                setSuccessText(i18n.t("recordSynched"))

                break

            default:
                setButtonText(i18n.t("addSyncRecord"))
                break
        }


    }, [atoBehavior, i18n, status])

    useEffect(() => {

        if (status === locCon.ATO_UP_TO_DATE)
            return
        else {

            if (!checkDependencies) {
                setIsDisabled(false)
                return
            }

            let sizeOfSet = bundleDevices.length

            let isMissing = config[libCon.NECESSARY_IDS].map(k => {
                let value = fields[k]

                // Is single dependency
                if (Object.values(config[libCon.SINGLE_DEPENDENCIES]).includes(k)) {
                    return (isNullOrUndefinedOrEmpty(value))

                }
                // Set Dependency
                else if (Object.values(config[libCon.SET_DEPENDENCIES]).includes(k)) {
                    return (isNullOrUndefinedOrEmpty(value) || value.length !== sizeOfSet)
                }
                // Other                
                return isNullOrUndefinedOrEmpty(value)

            })

            if (isMissing.some(v => v)) {
                setIsDisabled(true)

                let text = `${i18n.t("missing")}: ` + config[libCon.NECESSARY_IDS].filter((_, i) => isMissing[i]).join(", ")

                setWarningText(text)

            }
            else
                setIsDisabled(false)



        }



    }, [status, atoBehavior, fields, config, i18n, bundleDevices, checkDependencies])




    return (
        <div className='verticalSection'>
            {
                isSynching
                    ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                    : status === locCon.ATO_UP_TO_DATE && becomeTextOnSuccess
                        ? <CustomText type={locCon.SUCCESS_TEXT}>{successText}</CustomText>
                        : <CustomPrimaryButtonWithDisability isDisabled={forceDisable || isDisabled} title={forceDisable ? i18n.t("error") : i18n.t("missingValues")} description={forceDisable ? forceDisabledText : warningText} onClick={() => syncATObject()}>
                            {isNew ? <NewIcon color='var(--primary-color-5)' /> : <div></div>}
                            {buttonText}
                        </CustomPrimaryButtonWithDisability>
            }
        </div>
    )
}




export const BundleSyncProgress = ({ text, status }) => {

    return (<div style={{ width: "100%", display: "flex" }}>
        <div style={{ marginLeft: "0.5vh", marginRight: "0.5vh" }}>
            {
                status === libCon.STATUS_NOT_STARTED
                    ? <ClockCircleOutlined style={{ fontSize: 25 }} />
                    : status === libCon.STATUS_LOADING
                        ? <CustomSpin type={locCon.SMALL_SPINNER} />
                        : status === libCon.STATUS_OK
                            ? <CheckCircleTwoTone style={{ fontSize: 25 }} twoToneColor="#52c41a" />
                            : status === libCon.STATUS_NOT_FOUND
                                ? <QuestionCircleOutlined style={{ fontSize: 25 }} />
                                : <WarningTwoTone style={{ fontSize: 25 }} twoToneColor="#e91822" />
            }
        </div>
        <CustomText type={locCon.INFO_TEXT}>{text}</CustomText>

    </div>)

}

export const EndAndRemovePlacementButton = ({ placementATOid, onClickCleanup = (_) => true, forceSuccessText = false }) => {

    const placementId = getATOId(placementATOid)


    return (<GenericEndAndRemovePlacementButton placementId={placementId} resetATObjectMethod={() => resetATObject(placementATOid)} endPlacement={async () => endPlacement(locCon.AT_OBJECTS_CONFIGS[placementATOid][libCon.TABLE], placementId)} onClickCleanup={onClickCleanup} forceSuccessText={forceSuccessText} />)
}

export const EndAndRemovePlacementInsideSetButton = ({ positionId, placementATOid, onClickCleanup = (_) => true, forceSuccessText = false }) => {

    const placementId = RefATObjectInsideSetId(positionId, placementATOid)

    return (<GenericEndAndRemovePlacementButton placementId={placementId} resetATObjectMethod={() => resetATObjectInsideSet(positionId, placementATOid)} endPlacement={async () => endPlacement(locCon.AT_OBJECTS_CONFIGS[placementATOid][libCon.TABLE], placementId)} onClickCleanup={onClickCleanup} forceSuccessText={forceSuccessText} />)
}



export const GenericEndAndRemovePlacementButton = ({ placementId, resetATObjectMethod, endPlacement, onClickCleanup = (_) => true, forceSuccessText = false }) => {

    // Language
    const i18n = RefLanguage()

    const [isSynching, setisSynching] = useState(() => false)

    const removePlacement = async () => {

        let records, responseStatus

        setisSynching(true)

        // Placement
        let finsihed = true
        if (!isNullOrUndefined(placementId)) {
            [responseStatus, records] = await endPlacement()


            if (responseStatus === libCon.OK && !isNullOrUndefined(records)) {
                resetATObjectMethod()

            }
            else
                finsihed = false

        }

        onClickCleanup(finsihed)

        setisSynching(false)

    }

    return (<div className='verticalSection'>
        {
            isSynching
                ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                : isNullOrUndefined(placementId) || forceSuccessText
                    ? <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("placementRemovedSuccesfully")}</CustomText>
                    : <CustomButton type={locCon.PRIMARY_BUTTON} onClick={() => removePlacement()} >
                        {i18n.t("endAndRemovePlacement")}
                    </CustomButton>
        }
    </div>)



}