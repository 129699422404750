import React from 'react'
import CustomText from './CustomText'
import { DatePicker, TimePicker } from 'antd'
import * as locCon from "../LocalConstants"
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import dayjs from 'dayjs';
import { StateStoredValue } from '../hooks/StoreHooks';
import { nowInIndia, timeNowInIndia } from '../community-hats-js-library/utils/dateFunctions'

export function SimpleDatePickerFromId({ valueID, ...props }) {

    const [value, setValue] = StateStoredValue(valueID)

    return (<SimpleDatePicker value={value} setValue={setValue} {...props} />)
}



export function SimpleDatePicker({ value, setValue, title, infoText }) {


    return (
        <div className="verticalSection">
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}
            <DatePicker inputReadOnly allowClear={false} style={{ marginBottom: "1vh" }} onChange={(d, dateString) => setValue(dateString)} value={isNullOrUndefined(value) ? dayjs(nowInIndia(false)) : dayjs(value)} />

        </div>
    )
}

export function SimpleTimePickerFromId({ valueID, ...props }) {

    const [value, setValue] = StateStoredValue(valueID)

    return (<SimpleTimePicker inputReadOnly value={value} setValue={setValue} {...props} />)
}


export function SimpleTimePicker({ value, setValue, title, infoText }) {

    const FORMAT = 'HH:mm'

    return (
        <div className="verticalSection">
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}
            <TimePicker allowClear={false} style={{ marginBottom: "1vh" }} onChange={(time) => setValue(time.format(FORMAT))} value={isNullOrUndefined(value) ? dayjs(timeNowInIndia(false), "HH:mm") : dayjs(value, "HH:mm")} format={"HH:mm"} />
        </div>
    )
}



