import React, { useState } from 'react'
import * as locCon from '../LocalConstants';
import CustomText from '../elements/CustomText';
import { RefLanguage } from '../hooks/LanguageHooks';
import * as libCon from '../community-hats-js-library/Constants';
import CollectionStatus from '../elements/CollectionStatus';
import { RefHouseIntakeFormStatus, RefParticipantIntakeFormStatus, refreshHouse, refreshParticipant } from '../hooks/BundleDeviceHooks';
import { showErrorDialogByCode } from '../utils/dialogFunctions';
import { showNotification } from '../utils/generalFunctions';
import CustomSpin from '../elements/CustomSpin';
import CustomButton, { CustomPrimaryButtonWithDisability } from '../elements/CustomButton';
import { RefATObjectId } from '../hooks/StoreHooks';
import { isNullOrUndefined } from '../community-hats-js-library/utils/generalFunctions';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import { NewIcon } from '../elements/CustomIcons';

export const ParticipantIntakeFormStatus = () => {

    const i18n = RefLanguage()

    const [status, message] = RefParticipantIntakeFormStatus()
    const objectId = RefATObjectId(locCon.AT_OBJECT_PARTICIPANT)

    return (<IntakeFormStatusViewer
        title={<div><NewIcon />{i18n.t("participantIntakeForm")}</div>}
        status={status}
        message={message}
        objectId={objectId}
        refreshObject={refreshParticipant}
        incompleteDescription={i18n.t("addParticipant")}
        formPageId={locCon.PAGE_PARTICIPANT_INTAKE_FORM}
        formButtonText={i18n.t("participantIntakeForm")} />)
}


export const HouseIntakeFormStatus = () => {

    const i18n = RefLanguage()

    const [status, message] = RefHouseIntakeFormStatus()
    const objectId = RefATObjectId(locCon.AT_OBJECT_HOUSE)

    return (<IntakeFormStatusViewer
        title={<div><NewIcon />{i18n.t("houseIntakeForm")}</div>}
        status={status}
        message={message}
        objectId={objectId}
        refreshObject={refreshHouse}
        incompleteDescription={i18n.t("addHouse")}
        formPageId={locCon.PAGE_HOUSE_INTAKE_FORM}
        formButtonText={i18n.t("houseIntakeForm")} />)
}


function IntakeFormStatusViewer({ title, status, message, objectId, refreshObject, incompleteDescription, formPageId, formButtonText }) {

    const i18n = RefLanguage()

    // Current Page
    const setCurrentPage = RefSetCurrentPage()

    const [isSynching, setisSynching] = useState(false)


    const refreshRecords = async () => {
        setisSynching(true)
        let response = await refreshObject()

        if (response !== libCon.OK)
            showErrorDialogByCode(response)
        else
            showNotification(i18n.t("refreshComplete"))

        setisSynching(false)
    }


    return (
        <div className='verticalSection'>
            <CustomText type={locCon.SECTION_TITLE_TEXT}>{title}</CustomText>

            <CollectionStatus status={status} message={message} title={i18n.t("status")} />

            <div className='verticalSection'>
                {
                    isSynching
                        ? <CustomSpin type={locCon.BUTTON_SPINNER} />
                        : <CustomPrimaryButtonWithDisability isDisabled={isNullOrUndefined(objectId)} title={i18n.t("incompleteDeployment")} description={incompleteDescription} onClick={() => refreshRecords()}>{i18n.t("refresh")}</CustomPrimaryButtonWithDisability>
                }
            </div>

            {
                status === libCon.DEVICE_STATUS_WAITING_COLLECTION
                    ? <div className='verticalSection'>
                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("goToForm")}</CustomText>
                        <CustomText type={locCon.INSTRUCTIONS_TEXT}>{i18n.t("followLinkAndAdministerForm")}</CustomText>
                        <CustomButton style={{ marginTop: "1.5vh" }} type={locCon.PRIMARY_BUTTON} onClick={() => setCurrentPage(formPageId)}>{formButtonText}</CustomButton>
                    </div>
                    : <div></div>
            }

        </div>
    )
}

