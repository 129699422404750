
import { RefLanguage } from '../hooks/LanguageHooks';
import { buildPerceptualSurveyMeta, buildSurveyFilename, encodeHealthFormId } from '../utils/surveyFunctions';
import * as libCon from "./../community-hats-js-library/Constants"
import * as locCon from "./../LocalConstants"
import CustomText from '../elements/CustomText';
import Header from '../components/Header';
import { GenericSurveyProgressComponentAndExport, GenericSurveyFormStructureButton, GenericSurveyQuestions } from '../elements/SurveyElements';
import { getATOField } from '../hooks/StoreHooks';




export function PerceptualSurvey() {

  const i18n = RefLanguage()

  const encoder = encodeHealthFormId
  const surveyConfiguration = libCon.LATEST_PERCEPTUAL_SURVEY
  const configurationStatusId = locCon.HEALTH_FORM_CONFIGURATION_STATUS
  const instructionsText = i18n.t("healthFormInstructions")
  const fodlerName = libCon.PERCEPTUAL_SURVEY_FOLDER
  const metaBuilder = buildPerceptualSurveyMeta
  const buildFilename = buildSurveyFilename
  const sendingStatusId = locCon.HF_CONNECTION_SENDING_ANSWERS_STATUS
  const getSurveySerial = () => getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)



  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} includeBackButton={true} />

        <div className='verticalSection' style={{ marginBottom: 25 }}>

          <CustomText type={locCon.SECTION_TITLE_TEXT} style={{ marginBottom: "1.5vh" }}>{i18n.t("perceptualSurvey")}</CustomText>
          <GenericSurveyFormStructureButton surveyConfiguration={surveyConfiguration}
            configurationStatusId={configurationStatusId}
            sendingStatusId={sendingStatusId} />
          <GenericSurveyQuestions encoder={encoder}
            surveyConfiguration={surveyConfiguration}
            configurationStatusId={configurationStatusId}
            instructionsText={instructionsText} />
          <GenericSurveyProgressComponentAndExport surveyConfiguration={surveyConfiguration}
            sendingStatusId={sendingStatusId}
            configurationStatusId={configurationStatusId}
            buildFilename={buildFilename}
            fodlerName={fodlerName}
            encoder={encoder}
            metaBuilder={metaBuilder}
            getSurveySerial={getSurveySerial} />
        </div>
      </div>
    </div>
  );
}

export default PerceptualSurvey






