import React, { useEffect, useState } from "react";
import * as libCon from "./../community-hats-js-library/Constants"
import * as locCon from "./../LocalConstants"
import { RefLanguage } from "../hooks/LanguageHooks";
import * as h_fun from "./../community-hats-js-library/utils/healthFunctions"
import { encodeHealthFormId } from "../utils/surveyFunctions";
import { getStoredValue, StateStoredBooleanValue, StateStoredValue } from "../hooks/StoreHooks";
import CustomText from "../elements/CustomText";
import { showNotification } from "../utils/generalFunctions";
import { InputNumber } from "antd";
import CustomButton from "../elements/CustomButton";
import { CheckboxDefaultValueFromId } from "../elements/CustomCheckBox";
import { isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";
import { RequestIcon } from "../elements/CustomIcons";
import { ModalHook } from "../hooks/ModalHooks";


export function OralTemperatureForm({ title, infoText }) {



    const i18n = RefLanguage()

    const [temperature, setTemperature] = StateStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_ORAL_TEMPERATURE))

    // Modal
    const [modal, openModal] = ModalHook()


    const [temp1, setTemp1] = useState(() => {
        const t = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_ORAL_TEMPERATURE))
        if (t === null || t === undefined)
            return null
        else
            return t[libCon.HF_ORAL_TEMPERATURE_1]
    })


    const [temp2, setTemp2] = useState(() => {
        const t = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_ORAL_TEMPERATURE))
        if (isNullOrUndefined(t))
            return null
        else
            return t[libCon.HF_ORAL_TEMPERATURE_2]
    })


    const [temp3, setTemp3] = useState(() => {
        const t = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_ORAL_TEMPERATURE))
        if (isNullOrUndefined(t))
            return null
        else
            return t[libCon.HF_ORAL_TEMPERATURE_3]
    })


    const [editing, setEditing] = StateStoredBooleanValue(encodeHealthFormId(libCon.HF_CUSTOM_ORAL_TEMPERATURE) + locCon.EDITING_VALUE)

    const [needsThrid, setNeedsThrid] = useState(false)



    useEffect(() => {


        if (!isNullOrUndefined(temperature)) {

            if (temperature[libCon.HF_ORAL_TEMPERATURE_1] !== libCon.NA)
                setTemp1(temperature[libCon.HF_ORAL_TEMPERATURE_1])

            if (temperature[libCon.HF_ORAL_TEMPERATURE_2] !== libCon.NA)
                setTemp2(temperature[libCon.HF_ORAL_TEMPERATURE_2])

            if (temperature[libCon.HF_ORAL_TEMPERATURE_3] !== libCon.NA)
                setTemp3(temperature[libCon.HF_ORAL_TEMPERATURE_3])
        }
        else {
            setEditing(true)
            setTemp1(null)
            setTemp2(null)
            setTemp3(null)
            setNeedsThrid(null)

        }

    }, [temperature, setEditing])

    const startEditing = () => {

        setEditing(true)
    }

    const saveLocalValues = () => {
        setTemperature({
            [libCon.HF_ORAL_TEMPERATURE_1]: temp1,
            [libCon.HF_ORAL_TEMPERATURE_2]: temp2,
            [libCon.HF_ORAL_TEMPERATURE_3]: temp3,
        })
    }

    const checkAndSaveValues = () => {


        let check = [h_fun.oralTemperatureCheckFunction(temp1), h_fun.oralTemperatureCheckFunction(temp2)]
        let warning = [h_fun.oralTemperatureWarningFunction(temp1), h_fun.oralTemperatureWarningFunction(temp2)]

        if (needsThrid) {
            check = [...check, h_fun.oralTemperatureCheckFunction(temp3)]
            warning = [...warning, h_fun.oralTemperatureWarningFunction(temp3)]
        }

        if (check.some(v => v === false)) {
            showNotification(i18n.t("enteredValueNotValid"))
        }
        else if (warning.some(v => v === false)) {
            let extraText = `${temp1} and  ${temp2}`
            if (needsThrid)
                extraText = `${temp1}, ${temp2} and ${temp3}`


            openModal(i18n.t("unlikelyValue"), `${i18n.t("unlikelyTemperature")} ${extraText}`, [
                {
                    text: i18n.t("cancel"),
                    onClick: () => false,
                    type: locCon.MODULE_CANCEL_BUTTON,
                },
                {
                    text: i18n.t("valueIsCorrect"),
                    onClick: () => { saveValues() },
                    type: locCon.MODULE_REGULAR_BUTTON
                }
            ]);

        }
        else
            saveValues()

    }

    const saveValues = () => {


        // Checks if needs third
        // Aready is filling a third
        if (needsThrid) {
            saveLocalValues()
            setEditing(false)

        }
        else {
            // Checkf if needs to fill thirds
            let newNeedsAthird = h_fun.oralTemperatureNeedsThird(temp1, temp2)

            // Needs third
            if (newNeedsAthird) {
                setNeedsThrid(true)
                setEditing(true)
            }
            else // No need. Can continue
            {
                saveLocalValues()
                setEditing(false)
            }
        }
    }


    return (
        <div className="verticalSection">
            {modal}
            <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText>
            <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText>


            <div className="verticalSection">
                <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("measurement") + " 1"}</CustomText>
                <div className="verticalSection" style={{ width: "fit-content", marginBottom: "1vh" }}>
                    {
                        editing === true
                            ? <InputNumber inputMode="numeric" style={styles.input} onChange={setTemp1} value={temp1} placeholder={i18n.t("oralTemperature")} />
                            : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{temperature === null || temperature === undefined || temperature[libCon.HF_ORAL_TEMPERATURE_1] === null || temperature[libCon.HF_ORAL_TEMPERATURE_1] === undefined ? "----" : temperature[libCon.HF_ORAL_TEMPERATURE_1]}</CustomText>
                    }
                </div>
            </div >

            <div className="verticalSection">
                <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("measurement") + " 2"}</CustomText>
                <div className="verticalSection" style={{ width: "fit-content", marginBottom: "1vh" }}>
                    {
                        editing === true
                            ? <InputNumber inputMode="numeric" style={styles.input} onChange={setTemp2} value={temp2} placeholder={i18n.t("oralTemperature")} />
                            : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{temperature === null || temperature === undefined || temperature[libCon.HF_ORAL_TEMPERATURE_2] === null || temperature[libCon.HF_ORAL_TEMPERATURE_2] === undefined ? "----" : temperature[libCon.HF_ORAL_TEMPERATURE_2]}</CustomText>
                    }
                </div>

            </div >

            {
                needsThrid
                    ? <div className="verticalSection" style={{ marginTop: "1vh" }}>
                        <RequestIcon size={30} />
                        <CustomText type={locCon.INFO_TEXT}>{i18n.t("measurementsTooDifferent")}</CustomText>

                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("measurement") + " 3"}</CustomText>
                        <div className="verticalSection" style={{ width: "fit-content", marginBottom: "1vh" }}>
                            {
                                editing === true
                                    ? <InputNumber inputMode="numeric" style={styles.input} onChange={setTemp3} value={temp3} placeholder={i18n.t("oralTemperature")} />
                                    : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{temperature === null || temperature === undefined || temperature[libCon.HF_ORAL_TEMPERATURE_3] === null || temperature[libCon.HF_ORAL_TEMPERATURE_3] === undefined ? "----" : temperature[libCon.HF_ORAL_TEMPERATURE_3]}</CustomText>
                            }
                        </div>
                    </div >
                    : <div></div>

            }

            <CustomButton type={locCon.PRIMARY_BUTTON} style={{ width: "50%", marginTop: 15 }} onClick={() => editing ? checkAndSaveValues() : startEditing()}>
                {editing ? i18n.t("save") : i18n.t("edit")}
            </CustomButton>

            <CheckboxDefaultValueFromId valueID={encodeHealthFormId(libCon.HF_CUSTOM_ORAL_TEMPERATURE)} defaultValue={libCon.HF_TEMPERATURE_DEFAULT_ANSWER} text={i18n.t("informationNotAvailable")} isDefault={(newVal, _) => newVal !== null && newVal !== undefined && Object.keys(newVal).every(k => newVal[k] === libCon.HF_TEMPERATURE_DEFAULT_ANSWER[k])} setEditingValue={setEditing} />


        </div>


    )

}






export function BloodPressureForm({ title, infoText }) {


    const i18n = RefLanguage()

    const [bloodPressure, setBloodPressure] = StateStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE))

    // Modal
    const [modal, openModal] = ModalHook()


    const [systolic, setSystolic] = useState(() => {
        const bp = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE))
        if (bp === null || bp === undefined)
            return null
        else
            return bp[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1]
    })
    const [diastolic, setDiastolic] = useState(() => {
        const bp = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE))
        if (isNullOrUndefined(bp))
            return null
        else
            return bp[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1]
    })

    const [systolic2, setSystolic2] = useState(() => {
        const bp = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE))
        if (isNullOrUndefined(bp))
            return null
        else
            return bp[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2]
    })
    const [diastolic2, setDiastolic2] = useState(() => {
        const bp = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE))
        if (isNullOrUndefined(bp))
            return null
        else
            return bp[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2]
    })

    const [systolic3, setSystolic3] = useState(() => {
        const bp = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE))
        if (isNullOrUndefined(bp))
            return null
        else
            return bp[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3]
    })
    const [diastolic3, setDiastolic3] = useState(() => {
        const bp = getStoredValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE))
        if (isNullOrUndefined(bp))
            return null
        else
            return bp[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3]
    })

    const [editing, setEditing] = StateStoredBooleanValue(encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE) + locCon.EDITING_VALUE)

    const [needsThrid, setNeedsThrid] = useState(false)



    useEffect(() => {


        if (!isNullOrUndefined(bloodPressure)) {

            if (bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1] !== libCon.NA)
                setSystolic(bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1])

            if (bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1] !== libCon.NA)
                setDiastolic(bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1])

            if (bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2] !== libCon.NA)
                setSystolic2(bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2])

            if (bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2] !== libCon.NA)
                setDiastolic2(bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2])

            if (bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3] !== libCon.NA)
                setSystolic3(bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3])

            if (bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3] !== libCon.NA)
                setDiastolic3(bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3])



        }
        else {
            setEditing(true)
            setSystolic(null)
            setDiastolic(null)
            setSystolic2(null)
            setDiastolic2(null)
            setSystolic3(null)
            setDiastolic3(null)
            setNeedsThrid(null)
        }

    }, [bloodPressure, setEditing])

    const startEditing = () => {
        setEditing(true)

    }

    const saveLocalValues = () => {
        setBloodPressure({
            [libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1]: systolic,
            [libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1]: diastolic,
            [libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2]: systolic2,
            [libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2]: diastolic2,
            [libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3]: systolic3,
            [libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3]: diastolic3
        })

    }


    const checkAndSaveValues = () => {



        let check = [h_fun.bloodPressureCheckFunction(systolic, diastolic), h_fun.bloodPressureCheckFunction(systolic2, diastolic2)]
        let warning = [h_fun.bloodPressureWarningFunction(systolic, diastolic), h_fun.bloodPressureWarningFunction(systolic2, diastolic2)]

        if (needsThrid) {
            check = [...check, h_fun.bloodPressureCheckFunction(systolic3, diastolic3)]
            warning = [...warning, h_fun.bloodPressureWarningFunction(systolic3, diastolic3)]

        }



        if (check.some(v => v === false)) {
            showNotification(i18n.t("enteredValueNotValid"))
        }
        else if (warning.some(v => v === false)) {
            let extraText = `${systolic}/${diastolic} and  ${systolic2}/${diastolic2}`
            if (needsThrid)
                extraText = `${systolic}/${diastolic}, ${systolic2}/${diastolic2} and ${systolic3}/${diastolic3}`


            openModal(i18n.t("unlikelyValue"), `${i18n.t("unlikelyBLoodPressure")} ${extraText}`, [
                {
                    text: i18n.t("cancel"),
                    onClick: () => false,
                    type: locCon.MODULE_CANCEL_BUTTON,
                },
                {
                    text: i18n.t("valueIsCorrect"),
                    onClick: () => { saveValues() },
                    type: locCon.MODULE_REGULAR_BUTTON
                }
            ]);

        }
        else
            saveValues()






    }

    const saveValues = () => {


        // Checks if needs third
        // Aready is filling a third
        if (needsThrid) {
            saveLocalValues()
            setEditing(false)

        }
        else {
            // Checkf if needs to fill thirds
            let newNeedsAthird = h_fun.bloodPressureNeedsThird(systolic, diastolic, systolic2, diastolic2)

            // Needs third
            if (newNeedsAthird) {
                setNeedsThrid(true)
                setEditing(true)
            }
            else // No need. Can continue
            {
                saveLocalValues()
                setEditing(false)
            }
        }
    }


    return (
        <div className="verticalSection">
            {modal}
            <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText>
            <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText>


            <div className="verticalSection">
                <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("measurement") + " 1"}</CustomText>
                <div style={styles.left}>
                    <div className="verticalSection" style={{ width: "fit-content", marginBottom: "1vh" }}>
                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("systolic")}</CustomText>
                        {
                            editing === true
                                ? <InputNumber inputMode="numeric" style={styles.input} onChange={setSystolic} value={systolic} placeholder={i18n.t("systolic")} />
                                : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{bloodPressure === null || bloodPressure === undefined || bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1] === null || bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1] === undefined ? "----" : bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_1]}</CustomText>
                        }
                    </div>
                </div>
                <div style={{ marginnTop: "5vh", transform: "rotate(150deg)" }}>
                    <div style={styles.diagonalLine} />
                </div>
                <div style={styles.right} >
                    <div className="verticalSection" style={{ width: "fit-content" }}>
                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("diastolic")}</CustomText>
                        {
                            editing === true
                                ? <InputNumber inputMode="numeric" style={styles.input} onChange={setDiastolic} value={diastolic} placeholder={i18n.t("diastolic")} />
                                : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{bloodPressure === null || bloodPressure === undefined || bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1] === null || bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1] === undefined ? "----" : bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_1]}</CustomText>}
                    </div>
                </div>
            </div >

            <div className="verticalSection">
                <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("measurement") + " 2"}</CustomText>
                <div style={styles.left}>
                    <div className="verticalSection" style={{ width: "fit-content", marginBottom: "1vh" }}>
                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("systolic")}</CustomText>
                        {
                            editing === true
                                ? <InputNumber inputMode="numeric" style={styles.input} onChange={setSystolic2} value={systolic2} placeholder={i18n.t("systolic")} />
                                : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{bloodPressure === null || bloodPressure === undefined || bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2] === null || bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2] === undefined ? "----" : bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_2]}</CustomText>
                        }
                    </div>
                </div>
                <div style={{ marginnTop: "5vh", transform: "rotate(150deg)" }}>
                    <div style={styles.diagonalLine} />
                </div>
                <div style={styles.right} >
                    <div className="verticalSection" style={{ width: "fit-content" }}>
                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("diastolic")}</CustomText>
                        {
                            editing === true
                                ? <InputNumber inputMode="numeric" style={styles.input} onChange={setDiastolic2} value={diastolic2} placeholder={i18n.t("diastolic")} />
                                : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{bloodPressure === null || bloodPressure === undefined || bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2] === null || bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2] === undefined ? "----" : bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_2]}</CustomText>}
                    </div>
                </div>

            </div >

            {
                needsThrid
                    ? <div className="verticalSection" style={{ marginTop: "1vh" }}>
                        <RequestIcon size={30} />
                        <CustomText type={locCon.INFO_TEXT}>{i18n.t("measurementsTooDifferent")}</CustomText>

                        <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("measurement") + " 3"}</CustomText>
                        <div style={styles.left}>
                            <div className="verticalSection" style={{ width: "fit-content", marginBottom: "1vh" }}>
                                <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("systolic")}</CustomText>
                                {
                                    editing === true
                                        ? <InputNumber inputMode="numeric" style={styles.input} onChange={setSystolic3} value={systolic3} placeholder={i18n.t("systolic")} />
                                        : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{bloodPressure === null || bloodPressure === undefined || bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3] === null || bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3] === undefined ? "----" : bloodPressure[libCon.HEALTH_FORM_SYSTOLIC_BLOOD_PRESSURE_3]}</CustomText>
                                }
                            </div>
                        </div>
                        <div style={{ marginnTop: "5vh", transform: "rotate(150deg)" }}>
                            <div style={styles.diagonalLine} />
                        </div>
                        <div style={styles.right} >
                            <div className="verticalSection" style={{ width: "fit-content" }}>
                                <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{i18n.t("diastolic")}</CustomText>
                                {
                                    editing === true
                                        ? <InputNumber inputMode="numeric" style={styles.input} onChange={setDiastolic3} value={diastolic3} placeholder={i18n.t("diastolic")} />
                                        : <CustomText type={locCon.ELEMENT_TEXT} style={{ width: 190, height: 35 }}>{bloodPressure === null || bloodPressure === undefined || bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3] === null || bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3] === undefined ? "----" : bloodPressure[libCon.HEALTH_FORM_DIASTOLIC_BLOOD_PRESSURE_3]}</CustomText>}
                            </div>
                        </div>

                    </div >
                    : <div></div>

            }

            <CustomButton type={locCon.PRIMARY_BUTTON} style={{ width: "50%", marginTop: 15 }} onClick={() => editing ? checkAndSaveValues() : startEditing()}>
                {editing ? i18n.t("save") : i18n.t("edit")}
            </CustomButton>

            <CheckboxDefaultValueFromId valueID={encodeHealthFormId(libCon.HF_CUSTOM_BLOOD_PRESSURE)} defaultValue={libCon.HF_BLOOD_PRESSURE_DEFAULT_ANSWER} text={i18n.t("informationNotAvailable")} isDefault={(newVal, _) => newVal !== null && newVal !== undefined && Object.keys(newVal).every(k => newVal[k] === libCon.HF_BLOOD_PRESSURE_DEFAULT_ANSWER[k])} setEditingValue={setEditing} />


        </div>


    )
}


const styles = {
    input: {
        backgroundColor: "var(--primary-color-5)",
        color: "var(--primary-color-3)",
        width: "80%",
        marginLeft: "10%",
        marginRight: "10%",
        fontSize: 16,

    },
    diagonalLine: {
        marginTop: "3vh",
        marginBottom: "4vh",
        height: 2,
        backgroundColor: "var(--primary-color-3)",
        width: 100
    },
    right: {
        marginRight: "2wh",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "flex-end",
        alignItems: 'center',
        width: "100%",
        marginTop: "-2vh",
    },
    left: {
        marginLeft: "2wv",
        display: "flex",
        flexDirection: 'row',
        justifyContent: "flex-start",
        alignItems: 'center',
        width: "100%",
        marginBottom: "-1vh",
    }
};





