import React from "react";
import * as locCon from "../LocalConstants"
import * as libCon from "../community-hats-js-library/Constants"
import { RefLanguage } from "../hooks/LanguageHooks";
import CustomText from "./CustomText";
import { CheckboxDefaultValue } from "./CustomCheckBox";
import { StateStoredBooleanValue, StateStoredValue } from "../hooks/StoreHooks";
import { Radio } from "antd";
import { isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";
import { SimpleStoreCopyField } from "./SimpleStoreCopyField";


export function SimpleRadioButtonFromId({ valueID, ...props }) {
    const [value, setValue] = StateStoredValue(valueID)

    return (<SimpleRadioButton value={value} setValue={setValue} otherValueId={valueID + locCon.OTHER_VALUE} {...props} />)
}

export function SimpleRadioButtonImagesFromId({ valueID, ...props }) {
    const [value, setValue] = StateStoredValue(valueID)

    return (<SimpleRadioButtonWithImages value={value} setValue={setValue} otherValueId={valueID + locCon.OTHER_VALUE} {...props} />)
}


export function SimpleRadioButton({ value, setValue, title, infoText, options, optionsTextDict, orientation = locCon.HORIZONTAL, fontSize, includeAnswer = true, includeCheckbox = false, defaultValue = libCon.NA, checkboxText }) {

    const i18n = RefLanguage()

    return (
        <div className="verticalSection">
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}

            <div className="verticalSection" style={{ marginTop: "1.5vh", marginBottom: "1.5vh" }}>
                <Radio.Group onChange={(e) => setValue(e.target.value)} value={value} style={{ width: "100%" }} >
                    <div className={orientation === locCon.HORIZONTAL ? "horizontalSection" : "verticalSection"}>
                        {
                            options.map(val => <Radio key={val} value={val}>{optionsTextDict[val]}</Radio>)
                        }
                    </div>
                </Radio.Group>
            </div>

            {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={setValue} defaultValue={defaultValue} text={checkboxText} /> : <div></div>}
            {includeAnswer ? <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: "1vh" }}>{`${i18n.t("answer")}: ${value === defaultValue ? defaultValue : isNullOrUndefined(value) ? "----" : options.includes(value) ? optionsTextDict[value] : value}`}</CustomText> : <div></div>}
        </div>


    )
}




export function SimpleRadioButtonWithImages({ value,
    setValue,
    title,
    infoText,
    options,
    optionsTextDict,
    optionsImagesDict,
    orientation = locCon.HORIZONTAL,
    includeAnswer = true,
    includeOther = true,
    otherValueId,
    includeCheckbox = false,
    defaultValue = libCon.NA,
    checkboxText }) {

    const i18n = RefLanguage()




    // Other Value
    const [other, setOther] = StateStoredValue(otherValueId)
    const [editingOther, setEditingOther] = StateStoredBooleanValue(otherValueId + locCon.EDITING_VALUE)


    const toggleIncludeOtherAfterSave = (isEditing, value) => {
        if (!isEditing) {
            setValue(value)
        }
        else
            setValue(null)
    }


    const toggleIncludeOther = () => {
        if (!editingOther) {
            setValue(other)
        }
    }


    return (
        <div className="verticalSection">
            {title !== null ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {infoText !== null ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}

            <div className="verticalSection" style={{ marginTop: "1.5vh", marginBottom: "1.5vh" }}>
                <Radio.Group onChange={(e) => setValue(e.target.value)} value={value} style={{ width: "100%" }} >
                    <div className={orientation === locCon.HORIZONTAL ? "horizontalSectionLeft" : "verticalSection"}>
                        {

                            options.map(val =>

                                <div key={val} className="verticalSection" style={{ width: "50%", marginTop: "1vh" }}>
                                    <Radio value={val} style={{ marginBottom: "1vh" }}>
                                        {optionsTextDict[val]}
                                    </Radio>
                                    <img
                                        width={"90%"}
                                        src={optionsImagesDict[val]}
                                        alt={val}
                                        style={{ backgroundColor: 'white', padding: '5px' }}
                                        onClick={() => setValue(val)}
                                    />
                                </div>)

                        }


                        {
                            !includeOther
                                ? <div></div>
                                : <div className="verticalSection" style={{ width: "50%", marginTop: "1vh" }}>
                                    <Radio disabled={isNullOrUndefined(other) || editingOther} value={isNullOrUndefined(other) || editingOther ? "_______" : other} style={{ marginBottom: "1vh" }} onClick={() => toggleIncludeOther()}>
                                        {i18n.t("other")}
                                    </Radio>
                                    <div style={{ marginTop: "0.2vh", width: "100%", marginRight: "1vh" }}>
                                        <SimpleStoreCopyField value={other} setValue={setOther} editingValue={editingOther} setEditingValue={setEditingOther} enableCopy={false} placeHolder={i18n.t("other")} saveCleanFunction={(isEditing, value) => toggleIncludeOtherAfterSave(isEditing, value)} />
                                    </div>

                                </div>


                        }
                    </div>
                </Radio.Group>
            </div>

            {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={setValue} defaultValue={defaultValue} text={checkboxText} /> : <div></div>}
            {includeAnswer ? <CustomText type={locCon.INFO_TEXT} style={{ marginBottom: "1vh" }}>{`${i18n.t("answer")}: ${value === defaultValue ? defaultValue : isNullOrUndefined(value) ? "----" : options.includes(value) ? optionsTextDict[value] : value}`}</CustomText> : <div></div>}
        </div>


    )
}
