
import { RefLanguage } from '../hooks/LanguageHooks';
import { buildHouseIntakeFormMeta, buildHouseSurveyFilename, encodeHouseIntakeFormId } from '../utils/surveyFunctions';
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import CustomText from '../elements/CustomText';
import Header from '../components/Header';
import { GenericSurveyProgressComponentAndExport, GenericSurveyFormStructureButton, GenericSurveyQuestions } from '../elements/SurveyElements';
import { getATOField } from '../hooks/StoreHooks';



export function HouseIntakeForm() {

  const i18n = RefLanguage()


  const encoder = encodeHouseIntakeFormId
  const surveyConfiguration = libCon.LATEST_HOUSE_INTAKE_FORM
  const configurationStatusId = locCon.HOUSE_INTAKE_FORM_CONFIGURATION_STATUS
  const instructionsText = i18n.t("houseIntakeFormInstructions")
  const fodlerName = libCon.HOUSE_INTAKE_FORM_FOLDER
  const metaBuilder = buildHouseIntakeFormMeta
  const buildFilename = buildHouseSurveyFilename
  const sendingStatusId = locCon.HIF_CONNECTION_SENDING_ANSWERS_STATUS
  const getSurveySerial = () => getATOField(locCon.AT_OBJECT_HOUSE, libCon.ATF_NUMERIC_ID)



  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} includeBackButton={true} />

        <div className='verticalSection' style={{ marginBottom: 25 }}>

          <CustomText type={locCon.SECTION_TITLE_TEXT} style={{ marginBottom: "1.5vh" }}>{i18n.t("houseIntakeForm")}</CustomText>

          <GenericSurveyFormStructureButton surveyConfiguration={surveyConfiguration}
            configurationStatusId={configurationStatusId}
            sendingStatusId={sendingStatusId} />
          <GenericSurveyQuestions encoder={encoder}
            surveyConfiguration={surveyConfiguration}
            configurationStatusId={configurationStatusId}
            instructionsText={instructionsText} />
          <GenericSurveyProgressComponentAndExport surveyConfiguration={surveyConfiguration}
            sendingStatusId={sendingStatusId}
            configurationStatusId={configurationStatusId}
            buildFilename={buildFilename}
            fodlerName={fodlerName}
            encoder={encoder}
            metaBuilder={metaBuilder}
            getSurveySerial={getSurveySerial} />
        </div>
      </div>
    </div>
  );
}

export default HouseIntakeForm






