import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import * as types from "../store/types"
import { store } from '../store/store';
import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from "react-redux";
import { getRandomPositionId, isNullOrUndefined, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions';


export function RefStoredBooleanValue(key, defaultVal = false) {

    const value = useSelector(state => state[key])
    const [booleanValue, setBooleanValue] = useState(() => value === undefined || value === null ? defaultVal : `${value}` === "true")

    useEffect(() => {


        if (value === null || value === undefined)
            setBooleanValue(defaultVal)
        else
            setBooleanValue(`${value}` === "true")




    }, [value, defaultVal])



    return (booleanValue);
}

export function StateStoredBooleanValue(key, defaultVal = false) {

    const booleanValue = RefStoredBooleanValue(key, defaultVal)


    const setBooleanValue = useCallback((value) => {


        let payload = { [libCon.KEY]: key, [libCon.VALUE]: value === true }

        store.dispatch({
            type: types.SET_VALUE_IN_STORAGE,
            payload: payload
        })
    }, [key]);

    return ([booleanValue, setBooleanValue]);

}

export function RefStoredValue(key) {

    const value = useSelector(state => state[key])
    const [realValue, setRealValue] = useState(() => value === "null" || value === "undefined" || value === undefined ? null : value)

    useEffect(() => {
        if (value === "null" || value === "undefined" || value === undefined)
            setRealValue(null)
        else
            setRealValue(value)

    }, [value])


    return (realValue);
}

export function StateStoredValue(key) {

    const value = RefStoredValue(key)

    const setValue = useCallback((value) => {

        let payload = { [libCon.KEY]: key, [libCon.VALUE]: value }

        store.dispatch({
            type: types.SET_VALUE_IN_STORAGE,
            payload: payload
        })
    }, [key]);

    return ([value, setValue]);

}


export const getStoredValue = (key) => {
    let val = store.getState()[key]
    if (val === "null" || val === "undefined" || val === null || val === undefined)
        val = null
    return (val)
}

export const getStoredBooleanValue = (key) => {
    return (store.getState()[key] === true || `${store.getState()[key]}` === "true")
}

export const setStoredValue = (key, value) => {
    let payload = { [libCon.KEY]: key, [libCon.VALUE]: value }

    store.dispatch({
        type: types.SET_VALUE_IN_STORAGE,
        payload: payload
    })
}

export const RefStore = () => {
    const value = useSelector(state => state)
    return (value);

}



export function StateStoredMultipleValues(keys) {

    const selectors = keys.map(k => (state) => state[k])


    const multipleValueSelector = createSelector(selectors, (...selectedValues) => {

        return (keys.reduce((acc, key, index) => ({ ...acc, [key]: selectedValues[index] === "null" || selectedValues[index] === "undefined" || selectedValues[index] === undefined ? null : selectedValues[index] }), {}))

    })

    const values = useSelector(multipleValueSelector, shallowEqual)

    const setValues = (values) => {
        let payload = values

        store.dispatch({
            type: types.SET_MULTIPLE_VALUES_IN_STORAGE,
            payload: payload
        })
    }

    return ([values, setValues]);

}




export function RefStoredMultipleValues(keys) {

    const selectors = keys.map(k => (state) => state[k])


    const multipleValueSelector = createSelector(selectors, (...selectedValues) => {

        return (keys.reduce((acc, key, index) => ({ ...acc, [key]: selectedValues[index] === "null" || selectedValues[index] === "undefined" || selectedValues[index] === undefined ? null : selectedValues[index] }), {}))

    })

    const values = useSelector(multipleValueSelector, shallowEqual)

    return (values);

}




export function StateStoredMultipleBooleans(keys) {


    // Memoize the selector so it's only created once per unique set of keys
    const multipleValueSelector = useMemo(() => {
        const selectors = keys.map((key) => (state) => state[key]);

        return createSelector(selectors, (...selectedValues) => {
            return keys.reduce((acc, key, index) => {
                acc[key] = selectedValues[index] === true || selectedValues[index] === "true";
                return acc;
            }, {});
        });
    }, [keys]);

    // Use the selector to get values from the store
    const values = useSelector(multipleValueSelector);

    // Stable `setValues` function
    const setValues = useCallback(
        (newValues) => {
            store.dispatch({
                type: types.SET_MULTIPLE_VALUES_IN_STORAGE,
                payload: newValues,
            });
        },
        []
    );

    return [values, setValues];

}





export function setMultipleValues(dict) {

    store.dispatch({
        type: types.SET_MULTIPLE_VALUES_IN_STORAGE,
        payload: dict
    })


}

export function setMultipleCheckBoxes(checkBoxes, val) {

    const dict = Object.fromEntries(checkBoxes.map(key => [key, val === true]));

    store.dispatch({
        type: types.SET_MULTIPLE_VALUES_IN_STORAGE,
        payload: dict
    })


}



// Airtable Object Hooks
export function RefATObject(atoId) {

    const status = useSelector(state => { return state[atoId][libCon.STATUS] })
    const id = useSelector(state => { return state[atoId][libCon.ID] })
    const fields = useSelector(state => { return state[atoId][libCon.FIELDS] })

    return [status, id, fields]

}

// Airtable Object Hooks
export function RefATObjectId(atoId) {

    const id = useSelector(state => { return state[atoId][libCon.ID] })
    return id

}

export function RefATObjectStatus(atoId) {

    const status = useSelector(state => { return state[atoId][libCon.STATUS] })
    return status

}


// Airtable Object Hooks
export function RefATObjectInsideSet(positionId, atoId) {

    const status = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.STATUS] : null })
    const id = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.ID] : null })
    const fields = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.FIELDS] : null })

    return [status, id, fields]

}

// Airtable Object Hooks
export function RefATObjectInsideSetNoStatus(positionId, atoId) {

    const id = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.ID] : null })
    const fields = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.FIELDS] : null })

    return [id, fields]

}

// Airtable Object Hooks
export function RefATObjectInsideSetStatus(positionId, atoId) {

    const status = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.STATUS] : null })

    return status

}

// Airtable Object Hooks
export function RefATObjectInsideSetId(positionId, atoId) {

    const id = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.ID] : null })

    return id

}


// Airtable Object Hooks
export function RefATObjectInsideSetFields(positionId, atoId) {

    const fields = useSelector(state => { return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.FIELDS] : null })

    return fields

}


export function RefATOField(atoId, fieldId) {

    const value = useSelector(state => {
        return state[atoId][libCon.FIELDS][fieldId]
    })

    const [realValue, setRealValue] = useState(() => value === undefined ? null : value)

    useEffect(() => {
        if (value === "null" || value === "undefined" || value === undefined)
            setRealValue(null)
        else
            setRealValue(value)

    }, [value])


    return (realValue);

}

export function RefATOInsideSetField(positionId, atoId, fieldId) {

    const value = useSelector(state => {
        return positionId in state[locCon.AT_SET_OF_OBJECTS] ? state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.FIELDS][fieldId] : null
    })

    const [realValue, setRealValue] = useState(() => value === undefined ? null : value)

    useEffect(() => {
        if (value === "null" || value === "undefined" || value === undefined)
            setRealValue(null)
        else
            setRealValue(value)

    }, [value])


    return (realValue);

}


export function StateATOField(atoId, fieldId) {

    const value = RefATOField(atoId, fieldId)

    const setValue = useCallback((value) => {

        let payload = { [libCon.ID]: atoId, [libCon.KEY]: fieldId, [libCon.VALUE]: value }

        store.dispatch({
            type: types.SET_AIRTABLE_OBJECT_FIELD,
            payload: payload
        })
    }, [atoId, fieldId]);

    return ([value, setValue]);
}

export function StateATOInsideSetField(positionId, atoId, fieldId) {

    const value = RefATOInsideSetField(positionId, atoId, fieldId)

    const setValue = useCallback((value) => {

        let payload = { [libCon.POSITION_ID]: positionId, [libCon.ID]: atoId, [libCon.KEY]: fieldId, [libCon.VALUE]: value }

        store.dispatch({
            type: types.SET_AIRTABLE_OBJECT_INSIDE_SET_FIELD,
            payload: payload
        })
    }, [atoId, fieldId, positionId]);

    return ([value, setValue]);
}


export function RefKeysOfATOSet() {

    const value = useSelector(state => state[locCon.AT_SET_OF_OBJECTS])

    const [keys, setKeys] = useState(() => Object.keys(value))

    useEffect(() => {

        let newKeys = Object.keys(value)

        if (isNullOrUndefined(keys) || newKeys.length !== keys.length || newKeys.some((value, index) => value !== keys[index])) {
            setKeys(newKeys)
        }

    }, [value, keys])


    return (keys);
}

export function getKeysOfATOSet() {

    return (Object.keys(store.getState()[locCon.AT_SET_OF_OBJECTS]))
}



export function getATOField(atoId, fieldId) {

    const state = store.getState()
    if (atoId in state && libCon.FIELDS in state[atoId] && fieldId in state[atoId][libCon.FIELDS])
        return state[atoId][libCon.FIELDS][fieldId]

    return null

}

export function getATOInsideSetField(positionId, atoId, fieldId) {

    const state = store.getState()

    if (positionId in state[locCon.AT_SET_OF_OBJECTS] && atoId in state[locCon.AT_SET_OF_OBJECTS][positionId] && libCon.FIELDS in state[locCon.AT_SET_OF_OBJECTS][positionId][atoId] && fieldId in state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.FIELDS])
        return state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.FIELDS][fieldId]

    return null

}

export function getATOFields(atoId) {

    const state = store.getState()
    if (atoId in state && libCon.FIELDS in state[atoId])
        return state[atoId][libCon.FIELDS]

    return null

}

export function getATOId(atoId) {

    const state = store.getState()
    if (atoId in state && libCon.ID in state[atoId] && !isNullOrUndefined(state[atoId][libCon.ID]))
        return state[atoId][libCon.ID]

    return null

}


export function getATOIdInsideSet(positionId, atoId) {

    const state = store.getState()

    if (positionId in state[locCon.AT_SET_OF_OBJECTS] && atoId in state[locCon.AT_SET_OF_OBJECTS][positionId] && libCon.ID in state[locCon.AT_SET_OF_OBJECTS][positionId][atoId] && !isNullOrUndefined(state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.ID]))
        return state[locCon.AT_SET_OF_OBJECTS][positionId][atoId][libCon.ID]

    return null

}


export function setATOField(atoId, fieldId, value) {
    let payload = { [libCon.ID]: atoId, [libCon.KEY]: fieldId, [libCon.VALUE]: value }

    store.dispatch({
        type: types.SET_AIRTABLE_OBJECT_FIELD,
        payload: payload
    })

}

export function setATOFieldInsideSet(positionId, atoId, fieldId, value) {
    let payload = { [libCon.POSITION_ID]: positionId, [libCon.ID]: atoId, [libCon.KEY]: fieldId, [libCon.VALUE]: value }

    store.dispatch({
        type: types.SET_AIRTABLE_OBJECT_INSIDE_SET_FIELD,
        payload: payload
    })

}

export function setATOFieldForAllInsideSet(atoId, fieldId, value) {

    Object.keys(store.getState()[locCon.AT_SET_OF_OBJECTS]).forEach(positionId =>
        setATOFieldInsideSet(positionId, atoId, fieldId, value)
    )

}



export function setATObject(atoId, id, fields) {
    let payload = { [libCon.KEY]: atoId, [libCon.ID]: id, [libCon.FIELDS]: fields }

    store.dispatch({
        type: types.SET_AIRTABLE_OBJECT,
        payload: payload
    })

}

export function setATObjectInsideSet(positionId, atoId, id, fields) {
    let payload = { [libCon.POSITION_ID]: positionId, [libCon.KEY]: atoId, [libCon.ID]: id, [libCon.FIELDS]: fields }

    store.dispatch({
        type: types.SET_AIRTABLE_OBJECT_INSIDE_SET,
        payload: payload
    })

}


export const addNewPositionToATOSet = () => {
    let positionId = getRandomPositionId()
    let payload = { [libCon.POSITION_ID]: positionId }

    store.dispatch({
        type: types.ADD_NEW_POSITION_TO_ATO_SET,
        payload: payload
    })

    return positionId
}


export const deletePositionToATOSet = (positionId) => {
    let payload = { [libCon.POSITION_ID]: positionId }

    store.dispatch({
        type: types.DELETE_POSITION_TO_ATO_SET,
        payload: payload
    })

    return positionId
}


export const getSizeOfSet = () => {
    let val = store.getState()[locCon.AT_SET_OF_OBJECTS]
    return (Object.values(val).length)
}

export const getPositionIds = () => {
    let val = store.getState()[locCon.AT_SET_OF_OBJECTS]
    return [...Object.keys(val)]
}

export const restartBundleSetUp = () => {
    store.dispatch({
        type: types.RESTART_BUNDLE_SET_UP,
        payload: {}
    })

}

export const restartBundleForSync = () => {
    store.dispatch({
        type: types.RESTART_BUNDLE_FOR_SYNC,
        payload: {}
    })

}

export const restartBundleDeployment = () => {
    store.dispatch({
        type: types.RESTART_BUNDLE_DEPLOYMENT,
        payload: {}
    })

}


export const restartDataCollection = () => {
    store.dispatch({
        type: types.RESTART_DATA_COLLECTION_PROCESS,
        payload: {}
    })

}

export const RestartHealthForm = (keys) => {

    store.dispatch({
        type: types.RESTART_PERCEPTUAL_SURVEY,
        payload: { [libCon.VALUE]: keys }
    })
}

export const HasParticipant = () => {
    const id = useSelector(state => { return state[locCon.AT_OBJECT_PARTICIPANT][libCon.ID] })

    return (!isNullOrUndefinedOrEmpty(id))
}


