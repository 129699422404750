
import { RefLanguage } from '../hooks/LanguageHooks';
import { buildParticipantIntakeFormMeta, buildSurveyFilename, encodeParticipantIntakeFormId } from '../utils/surveyFunctions';
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import CustomText from '../elements/CustomText';
import Header from '../components/Header';
import { GenericSurveyProgressComponentAndExport, GenericSurveyFormStructureButton, GenericSurveyQuestions } from '../elements/SurveyElements';
import { getATOField } from '../hooks/StoreHooks';



export function ParticipantIntakeForm() {

  const i18n = RefLanguage()


  const encoder = encodeParticipantIntakeFormId
  const surveyConfiguration = libCon.LATEST_PARTICIPANT_INTAKE_FORM
  const configurationStatusId = locCon.PARTICIPANT_INTAKE_FORM_CONFIGURATION_STATUS
  const instructionsText = i18n.t("participantIntakeFormInstructions")
  const fodlerName = libCon.PARTICIPANT_INTAKE_FORM_FOLDER
  const metaBuilder = buildParticipantIntakeFormMeta
  const buildFilename = buildSurveyFilename
  const sendingStatusId = locCon.PIF_CONNECTION_SENDING_ANSWERS_STATUS
  const getSurveySerial = () => getATOField(locCon.AT_OBJECT_PARTICIPANT, libCon.ATF_SEWA_ID)



  return (
    <div className="mainAppContainer">
      <div className='pageContainer'>

        <Header long={true} includeBackButton={true} />

        <div className='verticalSection' style={{ marginBottom: 25 }}>

          <CustomText type={locCon.SECTION_TITLE_TEXT} style={{ marginBottom: "1.5vh" }}>{i18n.t("participantIntakeForm")}</CustomText>

          <GenericSurveyFormStructureButton surveyConfiguration={surveyConfiguration}
            configurationStatusId={configurationStatusId}
            sendingStatusId={sendingStatusId} />
          <GenericSurveyQuestions encoder={encoder}
            surveyConfiguration={surveyConfiguration}
            configurationStatusId={configurationStatusId}
            instructionsText={instructionsText} />
          <GenericSurveyProgressComponentAndExport surveyConfiguration={surveyConfiguration}
            sendingStatusId={sendingStatusId}
            configurationStatusId={configurationStatusId}
            buildFilename={buildFilename}
            fodlerName={fodlerName}
            encoder={encoder}
            metaBuilder={metaBuilder}
            getSurveySerial={getSurveySerial} />
        </div>
      </div>
    </div>
  );
}

export default ParticipantIntakeForm






