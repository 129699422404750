import * as locCon from './LocalConstants';

import './App.css';

import { RefCurrentPage } from './hooks/NavigationHooks';
import ManagerMainApp from './pages/ManagerMainApp';
import BundleSetUp from './pages/BundleSetUp';
import BundleDeploy from './pages/BundleDeploy';
import ToolsMainPage from './pages/ToolsMainPage';
import ToolLocationExctractor from './pages/ToolLocationExctractor';
import BundleCollectData from './pages/BundleCollectData';
import ToolFitbitTokenUpdater from './pages/ToolFitbitTokenUpdater';
import BundleCheckStatus from './pages/BundleCheckStatus';
import BundleSync from './pages/BundleSync';
import BundleManage from './pages/BundleManage';
import BundleRetrieve from './pages/BundleRetrieve';
import ReplaceWearable from './pages/ReplaceWearable';
import RemoveSensor from './pages/RemoveSensor';
import AddSensor from './pages/AddSensor';
import ChangeHouse from './pages/ChangeHouse';
import { RefIsLoggedIn } from './hooks/AuthenticationHooks';
import Authenticate from './pages/Authenticate';
import PerceptualSurvey from './pages/PerceptualSurvey';
import ParticipantIntakeForm from './pages/ParticipantIntakeForm';
import { useEffect, useState } from 'react';
import LoadingComponent from './components/LoadingComponent';
import Install from './pages/Install';
import { RefIsInstalled } from './hooks/InstallHooks';
import HouseIntakeForm from './pages/HouseIntakeForm';



function App() {

  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.orientation !== 0) {
        document.body.style.transform = 'rotate(-90deg)';
        document.body.style.transformOrigin = 'center';
      } else {
        document.body.style.transform = '';
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  const [allowsPWA, setAllowsPWA] = useState(true);

  useEffect(() => {
    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (event) => {
      setAllowsPWA(true)
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);


  // Navigation
  const currentPage = RefCurrentPage();

  // Authentication
  const isLoggedIn = RefIsLoggedIn()

  const [isLoading, setIsLoading] = useState(true)

  const isInstalled = RefIsInstalled()


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [])




  // Uses a function through switch to render
  const renderComponents = (page) => {

    switch (page) {
      case locCon.PAGE_MANAGER_START:
        return (<ManagerMainApp />)
      case locCon.PAGE_SET_UP_BUNDLE:
        return (<BundleSetUp />)
      case locCon.PAGE_DEPLOY_BUNDLE:
        return (<BundleDeploy />)
      case locCon.PAGE_SYNC_BUNDLE:
        return (<BundleSync />)
      case locCon.PAGE_COLLECT_DATA:
        return (<BundleCollectData />)
      case locCon.PAGE_BUNDLE_STATUS:
        return (<BundleCheckStatus />)
      case locCon.PAGE_BUNDLE_RETRIEVE:
        return (<BundleRetrieve />)
      case locCon.PAGE_MANAGE_BUNDLE:
        return (<BundleManage />)
      case locCon.PAGE_MANAGE_BUNDLE_REPLACE_WEARABLE:
        return (<ReplaceWearable />)
      case locCon.PAGE_MANAGE_BUNDLE_REMOVE_SENSOR:
        return (<RemoveSensor />)
      case locCon.PAGE_MANAGE_BUNDLE_ADD_SENSOR:
        return (<AddSensor />)
      case locCon.PAGE_MANAGE_BUNDLE_CHANGE_HOUSE:
        return (<ChangeHouse />)
      case locCon.PAGE_PERCEPTUAL_SURVEY:
        return (<PerceptualSurvey />)
      case locCon.PAGE_PARTICIPANT_INTAKE_FORM:
        return (<ParticipantIntakeForm />)
      case locCon.PAGE_HOUSE_INTAKE_FORM:
        return (<HouseIntakeForm />)
      case locCon.PAGE_TOOLS_MAIN:
        return (<ToolsMainPage />)
      case locCon.PAGE_TOOL_LOCATION_EXTRACTOR:
        return (<ToolLocationExctractor />)
      case locCon.PAGE_TOOL_FITBIT_API_UPDATER:
        return (<ToolFitbitTokenUpdater />)
      default:
        return (<ManagerMainApp />)
    }
  }

  return (
    <div>
      {
        isLoading ?
          <LoadingComponent />
          : !locCon.DEBUG && !isInstalled && allowsPWA ?
            <Install />
            : locCon.DEBUG || isLoggedIn
              ? renderComponents(currentPage)
              : <Authenticate />
      }

    </div>
  );
}

export default App;


