// Health Form Hooks
import { useEffect, useState } from "react"
//import * as locCon from "../LocalConstants"
import * as libCon from "../community-hats-js-library/Constants"
import { RefStoredMultipleValues } from "./StoreHooks";



// Watches all the store which is not ideal
export function SurveyProgress(surveyConfiguration, encoder) {

    const [totalQuestions, setTotalQuestions] = useState(null)
    const [totalAnswered, setTotalAnswered] = useState(null)

    const values = RefStoredMultipleValues(surveyConfiguration[libCon.SURVEY_STRUCTURE].map(ob => encoder(ob[libCon.SURVEY_QUESTION_ID])))


    useEffect(() => {


        if (values != null && Object.keys(values).length > 0) {
            setTotalQuestions(Object.keys(values).length)
            setTotalAnswered(Object.values(values).filter(val => val !== null && val !== undefined).length)
        }
        else {
            setTotalQuestions(null)
            setTotalAnswered(null)
        }

    }, [values])


    return [totalQuestions, totalAnswered]

}
