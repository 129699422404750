import React, { useEffect, useState } from "react";
import * as locCon from "../LocalConstants"
import * as libCon from "../community-hats-js-library/Constants"
import CustomText from "./CustomText";
import { UploadOutlined } from "@ant-design/icons";
import { generateRandomString, isNullOrUndefined } from "../community-hats-js-library/utils/generalFunctions";
import { Progress, Upload } from "antd";
import { StateStoredValue } from "../hooks/StoreHooks";
import { uploadImageToBucket } from "../community-hats-js-library/utils/AWSFunctions";
import { RefLanguage } from "../hooks/LanguageHooks";
import CustomButton from "./CustomButton";
import { CheckboxDefaultValue } from "./CustomCheckBox";
import { showErrorDialogByCode } from "../utils/dialogFunctions";


export const SimpleImageUploadFromId = ({ valueID, ...props }) => {

    const [value, setValue] = StateStoredValue(valueID)
    const [imageUrl, setImageUrl] = useState(valueID + locCon.PREVIEW_VALUE);

    return (<SimpleImageUpload
        value={value}
        setValue={setValue}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        {...props} />)

}





export const SimpleImageUpload = ({ value,
    setValue,
    title,
    infoText,
    infoImage = null,
    imageUrl,
    setImageUrl,
    includeCheckbox = false,
    defaultValue = libCon.NA,
    checkboxText }) => {

    const i18n = RefLanguage()

    const [uploadProgress, setUploadProgress] = useState(0);

    const [isImageValid, setIsImageValid] = useState(null)

    useEffect(() => {

        if (isNullOrUndefined(imageUrl)) {
            setIsImageValid(null)
            return
        }

        const img = new window.Image();
        img.onload = () => setIsImageValid(true);
        img.onerror = () => setIsImageValid(false);
        img.src = imageUrl

    }, [imageUrl])



    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setImageUrl(file.url || file.preview);
    };

    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };


    const customUpload = async ({ file, onProgress, onSuccess, onError }) => {


        setUploadProgress(0)

        const onProgressFun = (loaded, total) => {
            onProgress({ percent: Math.round(100 * loaded / total) })
            setUploadProgress(Math.round(100 * loaded / total))
        }

        const fileName = generateRandomString(5) + "_" + file.name.replaceAll(" ", "_")

        const response = await uploadImageToBucket(libCon.SURVEY_IMAGES_FOLDER, fileName, file, onProgressFun)

        if (response === libCon.OK) {
            onSuccess()
            setValue(fileName)

        }
        else {
            showErrorDialogByCode(response)
            onError()
        }

    };

    useEffect(() => {
        if (isNullOrUndefined(value) || value === defaultValue) {
            setUploadProgress(0)
            setImageUrl(null)

        }
        else {
            setUploadProgress(100)
        }

    }, [value, defaultValue, setImageUrl])


    return (
        <div className="verticalSection">
            {!isNullOrUndefined(title) ? <CustomText type={locCon.ELEMENT_TITLE_TEXT}>{title}</CustomText> : <div></div>}
            {!isNullOrUndefined(infoText) ? <CustomText type={locCon.INFO_TEXT}>{infoText}</CustomText> : <div></div>}
            {!isNullOrUndefined(infoImage) ? <img src={infoImage} alt={"info"} style={{ maxWidth: '50vh', maxHeight: '50vh', backgroundColor: "white" }} /> : <div></div>}


            <div className="verticalSection" style={{ maxWidth: "80%", marginTop: "3vh" }}>
                <Progress percent={uploadProgress} strokeColor={"var(--primary-color-3)"} size={{ height: "1vh" }} />
            </div>

            <div className="verticalSection" style={{ maxWidth: '15vh', maxHeight: '15vh', width: '15vh', height: '15vh', marginBottom: "2vh" }}>
                {
                    isNullOrUndefined(value) ?
                        <div style={{ width: "100%", height: "100%", backgroundColor: "var(--background-color-2)" }}></div>
                        : value === defaultValue
                            ? <div className="verticalSection" style={{ width: "100%", height: "100%", marginTop: "40%" }}>
                                <CustomText type={locCon.SUCCESS_TEXT}>{defaultValue}</CustomText>
                            </div>
                            : isImageValid === true
                                ? <img src={imageUrl} alt={value} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                : <div className="verticalSection" style={{ width: "100%", height: "100%", marginTop: "40%" }}>
                                    <CustomText type={locCon.SUCCESS_TEXT}>{i18n.t("imageUploadedCorrectly")}</CustomText>
                                </div>
                }
            </div>

            <Upload
                customRequest={customUpload}
                showUploadList={false}
                maxCount={1}
                onChange={({ file }) => handlePreview(file)}>
                <div className="verticalSection">
                    <CustomButton type={locCon.PRIMARY_BUTTON} icon={<UploadOutlined />} style={{ width: "20vh" }}>{i18n.t("upload")}</CustomButton>
                </div>
            </Upload>


            {includeCheckbox ? <CheckboxDefaultValue value={value} setValue={setValue} defaultValue={defaultValue} text={checkboxText} /> : <div></div>}


        </div>


    )
}







